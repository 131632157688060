import AuthService from "../session/AuthService";

export const APP_NAME = "VideoTagging";

export const youtubeKey = "AIzaSyDTkrfC36vwfIf-IOcgXQI_jwOKN1YMuSI";

let allowedDomains = ["artest.betalearnings.com" , "piqtime.com"];

let URL = "";
if (["localhost", "127.0.0.1"].includes(window.location.hostname)) {
  //  URL = "http://localhost:8081/"; //local
  //  URL = "https://piqtime.com/VideoTaggingAPI/"; //local
   URL = "https://artest.betalearnings.com/VideoTaggingAPI/"; //testing
} else if (allowedDomains.includes(window.location.hostname)) {
  URL = `https://${window.location.hostname}/VideoTaggingAPI/`;
} else {
  URL = `http://noaccess/permissiondenied/`;
}


export const API_URL = URL;

export const HTTP_HEADERS = {
  "Content-Type": "application/json",
  Authorization: "PRO-JWT",
};

class HTTP {
  HEADERS() {
    return {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: "JWT fefege...",
    };
  }
  AUTH_HEADERS() {
    return {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: AuthService.getToken(),
    };
  }
}

export default new HTTP();

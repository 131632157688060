import * as APPCONFIG from "../config/app-config.js";
import auth from "./AuthAPI.js";
import Http from "./Http.js";

class VideoService {
  getAllVideoDetails(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/select-annotation-videos`,
      data: JSON.stringify(form),
    });
  }
  getAllViewedVideos(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/select-view-videos`,
      data: JSON.stringify(form),
    });
  }
  getAllSharedVideos(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/select-shared-videos`,
      data: JSON.stringify(form),
    });
  }
  getAllPopularVideos(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/most-popular`,
      data: JSON.stringify(form),
    });
  }
  getSelectedVideo(form) {
    return auth({
      method: "post",
      headers: Http.HEADERS(),
      url: `${APPCONFIG.API_URL}auth/selected-video`,
      data: JSON.stringify(form),
    });
  }
  saveEvents(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/inserttagmaster`,
      data: JSON.stringify(form),
    });
  }
  updateEvents(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/update_tag_master`,
      data: JSON.stringify(form),
    });
  }
  saveSharedVideo(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/save-shared-video`,
      data: JSON.stringify(form),
    });
  }
  deleteObject(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/delete-object`,
      data: JSON.stringify(form),
    });
  }
  saveAccess(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/saveAccess`,
      data: JSON.stringify(form),
    });
  }
  updateObjects(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/update-objects`,
      data: JSON.stringify(form),
    });
  }
  getAllusersName(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/getAllUsers`,
      data: JSON.stringify(form),
    });
  }
  saveObjects(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/insertvideoobjects`,
      data: JSON.stringify(form),
    });
  }
  saveVideoLink(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/insertvideomaster`,
      data: JSON.stringify(form),
    });
  }
  saveExtraAccess(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/save-extra-access`,
      data: JSON.stringify(form),
    });
  }
  saveTimeLineDetails(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/save-timeline`,
      data: JSON.stringify(form),
    });
  }
  getCloneTimeDetails(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/get-timeline`,
      data: JSON.stringify(form),
    });
  }
  getAllCloneDetails(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/get-all-timline`,
      data: JSON.stringify(form),
    });
  }
  deleteVideo(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/delete-image`,
      data: JSON.stringify(form),
    });
  }
  deleteAccessDetails(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/delete-access`,
      data: JSON.stringify(form),
    });
  }

  deleteSharedVideo(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/delete-shared-video`,
      data: JSON.stringify(form),
    });
  }

  deleteViewedVideo(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/delete-viewed-video`,
      data: JSON.stringify(form),
    });
  }
  
  deleteEvents(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/delete-events`,
      data: JSON.stringify(form),
    });
  }
  updateSettings(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/update-settings`,
      data: JSON.stringify(form),
    });
  }
  updateTimelineId(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/update-timeline-id`,
      data: JSON.stringify(form),
    });
  }
  removeTimelineId(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/remove-timeline-id`,
      data: JSON.stringify(form),
    });
  }
  deleteTimeline(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/delete-timeline`,
      data: JSON.stringify(form),
    });
  }
  updateVideoDetails(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/update-video-details`,
      data: JSON.stringify(form),
    });
  }
  updateTimelineDetails(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/update-timeline-details`,
      data: JSON.stringify(form),
    });
  }
  insertViewedVideo(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/save-viewed-videos`,
      data: JSON.stringify(form),
    });
  }

  saveMostViewedVideos(form) {
    return auth({
      method: "post",
      headers: Http.HEADERS(),
      url: `${APPCONFIG.API_URL}auth/save-most-popular`,
      data: JSON.stringify(form),
    });
  }
}
export default new VideoService();

import * as APPCONFIG from '../config/app-config.js';
import auth from './AuthAPI.js';
import api from './BaseAPI.js';
import Http from './Http.js';

class LoginService {
    doLogout() {
        return auth({
            method: 'post',
            headers: Http.AUTH_HEADERS(),
            url: `${APPCONFIG.API_URL}api/logout`
        })
    }

    isLogin() {
        return auth({
            method: 'post',
            headers: Http.AUTH_HEADERS(),
            url: `${APPCONFIG.API_URL}islogin`
        })
    }



    getLoginValues(formValues) {
        return auth({
            method: 'post',
            headers: Http.AUTH_HEADERS(),
            url: `${APPCONFIG.API_URL}api/login`,
            data: JSON.stringify(formValues)
        })
    }

    //* NON AUTH *//

    getLogin(formValues) {
        return api({
            method: 'post',
            url: `${APPCONFIG.API_URL}auth/login`,
            data: JSON.stringify(formValues)
        })
    }
}

export default new LoginService();
import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import AuthService from '../session/AuthService.js';

class AuthRoute extends Component {
    render() {
        if (AuthService.isAuth()) {
            return <Route {...this.props} />
        } else {
            return <Redirect to="/login" />
        }
    }
}

export default AuthRoute;
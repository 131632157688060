import CryptoJS from "crypto-js";
import React, { Component, memo } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import "react-widgets/styles.css";
import Swal from "sweetalert2";
import ManageUserService from "../../api/ManageUserService";

var Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 1000,
});

class NewUserDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: {},
    };
    this.mobile_pattern = /^(\+\d{1,3}[- ]?)?\d+$/;
  }

  componentDidMount() {
    if (this.props.obj && this.props.obj[0]) {
      this.setState({
        formValues: { ...this.props.obj[0] },
      });
    }
  }

  render() {
    return (
      <Modal show={true} size="lg" centered>
        <Form noValidate validated={this.state.validated} autoComplete="off">
          <Modal.Header
            className="CNSHeader"
            onHide={() => this.props.onHide()}
          >
            {this.props.option === "new" && (
              <h3 className="titleMd uppercase">Create New User</h3>
            )}
            {this.props.option === "edit" && (
              <h3 className="titleMd uppercase">Edit User</h3>
            )}
          </Modal.Header>
          <Modal.Body className="CNSBody ">
            <div className="basicInfoWrapper">
              <div className="row">
                <div className="col">
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>
                      User Name <span className="text-danger fw-bold">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      autoComplete="off"
                      required
                      name="name"
                      value={this.state.formValues.name}
                      onChange={(e) => this.handleForm(e)}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>
                      Email ID <span className="text-danger fw-bold">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Email Address"
                      autoComplete="off"
                      required
                      name="mailID"
                      disabled={this.props.option === "edit" ? true : false}
                      value={this.state.formValues.mailID}
                      onChange={(e) => this.handleForm(e)}
                    />
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mb-3" controlId="formBasicNo">
                    <Form.Label>Phone No</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Phone Number"
                      autoComplete="off"
                      required
                      name="phone"
                      value={this.state.formValues.phone}
                      onChange={(e) => this.handleForm(e)}
                      onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Form.Group className="mb-3" controlId="formBasicNo">
                    <Form.Label>
                      Password <span className="text-danger fw-bold">*</span>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      autoComplete="off"
                      required
                      name="cpassword"
                      value={this.state.formValues.cpassword}
                      onChange={(e) => this.handleForm(e)}
                    />
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mb-3" controlId="formBasicNo">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Address"
                      required
                      autoComplete="off"
                      name="address"
                      value={
                        this.state.formValues.address == null
                          ? "N/A"
                          : this.state.formValues.address
                      }
                      onChange={(e) => this.handleForm(e)}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="CNSFooter">
            {this.props.option === "new" && (
              <button
                type="button"
                className="defaultBtn contained"
                onClick={(e) => this.saveForm(e)}
              >
                Save{"    "}
                {this.state.showLoader && (
                  <Spinner animation="border" size="sm" color="white" />
                )}
              </button>
            )}
            {this.props.option === "edit" && (
              <button
                type="button"
                className="defaultBtn contained"
                onClick={(e) => this.editForm(e)}
              >
                Edit{" "}
                {this.state.showLoader && (
                  <Spinner animation="border" size="sm" color="white" />
                )}
              </button>
            )}
            <button
              type="button"
              className="defaultBtn"
              onClick={() => this.props.onHide()}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  saveForm = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      // Toast.fire({
      //   icon: "warning",
      //   title: "Please enter valid details",
      // });
    } else {
      event.preventDefault();
      event.stopPropagation();

      if (!this.state.formValues.name) {
        Toast.fire({
          icon: "warning",
          title: "Please enter valid name",
        });
      } else if (
        !this.state.formValues.phone ||
        !this.mobile_pattern.test(this.state.formValues.phone)
      ) {
        Toast.fire({
          icon: "warning",
          title: "Please check the entered phone number",
        });
      } else if (
        !this.state.formValues.mailID ||
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          this.state.formValues.mailID
        )
      ) {
        Toast.fire({
          icon: "warning",
          title: "Please enter valid email",
        });
      } else if (!this.state.formValues.address) {
        Toast.fire({
          icon: "warning",
          title: "Please enter address",
        });
      } else if (!this.state.formValues.cpassword) {
        Toast.fire({
          icon: "warning",
          title: "Please Enter Password",
        });
      } else {
        this.setState({
          showLoader: true,
        });

        let form = this.state.formValues;
        form["password"] = CryptoJS.MD5(
          this.state.formValues.cpassword
        ).toString();
        form["user_type"] = "2";

        ManageUserService.saveUser(form)
          .then((response) => {
            if (response.data && response.data.status === 1) {
              this.props.loadStaff(0);
              this.props.onHide();
            } else {
              Toast.fire({
                icon: "error",
                title: "User Save Failed",
              });
            }
          })
          .catch((error) => {});
      }
    }
  };

  handleForm = (event) => {
    console.log("target values" + event.target.value);
    if(event.target.value === "e") return;
    var form = this.state.formValues;
    form[event.target.name] = event.target.value;
    this.setState({ formValues: form });
  };

  editForm = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      Toast.fire({
        icon: "warning",
        title: "Please enter valid details",
      });
    } else {
      event.preventDefault();
      event.stopPropagation();

      if (!this.state.formValues.name) {
        Toast.fire({
          icon: "warning",
          title: "Please enter valid name",
        });
      } else if (
        this.state.formValues.phone &&
        !this.mobile_pattern.test(this.state.formValues.phone)
      ) {
        Toast.fire({
          icon: "warning",
          title: "Please check the entered phone number",
        });
      } else if (
        !this.state.formValues.mailID ||
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          this.state.formValues.mailID
        )
      ) {
        Toast.fire({
          icon: "warning",
          title: "Please enter valid email",
        });
      } else if (!this.state.formValues.cpassword) {
        Toast.fire({
          icon: "warning",
          title: "Please Enter Password",
        });
      } else {
        this.setState({
          showLoader: true,
        });
        let form = this.state.formValues;
        form["password"] = CryptoJS.MD5(
          this.state.formValues.cpassword
        ).toString();

        ManageUserService.editUser(form)
          .then((response) => {
            if (response.data && response.data.data) {
              this.props.onHide();
              // this.props.loadStaffEdit(0);
            } else {
              Toast.fire({
                icon: "error",
                title: "User Update Failed",
              });
            }
          })
          .catch((error) => {});
      }
    }
  };
}

export default memo(NewUserDialog);

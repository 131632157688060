import {
  GET_ALL_FILTERED_USERS,
  GET_ALL_USERS_BY_ID,
  GET_ALL_USER_DETAILS,
} from "../types";

const initialState = {
  userlist: [],
  userCount: 0,
  filteredUser: [],
  loading: true,
  userListName: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_USER_DETAILS:
      return {
        ...state,
        userlist: action.payload.userList,
        userCount: action.payload.userCount,
        loading: false,
      };
    case GET_ALL_USERS_BY_ID:
      return {
        ...state,
        userListName: action.payload,
      };
    case GET_ALL_FILTERED_USERS:
      return {
        ...state,
        filteredUser: action.payload.filteredUser,
        loading: false,
      };
    default:
      return state;
  }
}

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { Component } from "react";
import Swal from "sweetalert2";
import VideoService from "../../api/VideoService";
import backwardTenSec from "../../images/backward.jpg";
import forwardTenSec from "../../images/forward.jpg";
import addTimeline from "../../vImages/add.svg";
import deleteImg from "../../vImages/delete.svg";
import pauseCurrent from "../../vImages/pause-current.svg";
import playAllDark from "../../vImages/play-all-dark.svg";
import playAll from "../../vImages/play-all.svg";
import playCurrent from "../../vImages/play-current.svg";
import play_unfill from "../../vImages/play_unfill.svg";
import rewindImage from "../../vImages/rewind-current.svg";
import rewind_unfill from "../../vImages/rewind_unfill.svg";

var Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 1000,
});

function highLightTags(obj) {
  let data = false,
    timeline = [];
  timeline.push(obj.timeline);
  let filteredObject = timeline.filter((o) =>
    String(o.subscribedTags).includes(String(obj.tagId))
  );
  if (filteredObject && filteredObject.length > 0) {
    let checkTimeline = filteredObject.map((d) => d.id);
    if (checkTimeline && checkTimeline.length > 0) {
      if (checkTimeline.includes(obj.timelineId)) {
        data = true;
      }
    }
  }
  return data;
}

const popperRef = React.createRef(null);
const areaRef = React.createRef(null);

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "white",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: "bold",
    border: "none",
  },
}));

class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      highLightedTags: [],
      time: 0,
      currentTime: 0,
      enableEdit: false,
      editIndex: null,
    };

    this.positionRef = React.createRef({
      x: 0,
      y: 0,
    });
  }
  render() {
    return (
      <div className="mw-100 p-0 overflow-hidden">
        {this.props.timelineDetails &&
          this.props.timelineDetails.map((time, i) => (
            <>
              <div className="mb-5 p-0">
                <div className="fs-6 fw-bold pointer">
                  <cite
                    style={{
                      border:
                        this.state.enableEdit === true &&
                        this.state.editIndex === i
                          ? "1px solid black"
                          : "none",
                      outline: "none",
                      fontWeight: "bold",
                      fontSize: "large",
                      padding:
                        this.state.enableEdit === true &&
                        this.state.editIndex === i
                          ? "0px"
                          : "5px",
                      minHeight: "0px",
                    }}
                    id={`${time.id}-timeline-name`}
                    onMouseEnter={() => {
                      this.setState({ enableEdit: true, editIndex: i });
                    }}
                    onBlur={() => {
                      this.setState(
                        { enableEdit: false, editIndex: null },
                        () => {
                          this.updateTimelineDetails(time);
                        }
                      );
                    }}
                    onMouseLeave={() => {
                      this.setState(
                        { enableEdit: false, editIndex: null },
                        () => {
                          //  this.updateTimelineDetails(time);
                        }
                      );
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        this.updateTimelineDetails(time);
                      }
                    }}
                    contenteditable="true"
                  >
                    {time.name}
                  </cite>
                </div>
                <div className="row">
                  <div className="col mb-1">
                    {this.props.timelineDetails &&
                      this.props.timelineDetails.length > 1 && (
                        <>
                          {!this.props.share && (
                            <>
                              <img
                                loading="lazy"
                                onClick={() =>
                                  this.props.deleteTimeline(time.id)
                                }
                                title="Delete timeline"
                                className="icon-standard-size me-1 max-width-25 pointer"
                                src={deleteImg}
                              />
                            </>
                          )}
                        </>
                      )}
                    {this.props.playingCommon &&
                    this.props.playingCommon.length > 0 &&
                    this.props.playingCommon[i] &&
                    this.props.playingCommon[i].playing ? (
                      <>
                        <img
                          loading="lazy"
                          title="Rewind video"
                          onClick={() => this.props.rewindVideo(i)}
                          className="icon-standard-size me-1 max-width-25 pointer"
                          src={rewindImage}
                        />
                        {this.props.playingCommon[i].show === false ? (
                          <>
                            <img
                              loading="lazy"
                              title="Play video"
                              onClick={() => this.props.playVideo(i)}
                              className="icon-standard-size me-1 max-width-24 pointer"
                              src={playCurrent}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              loading="lazy"
                              title="Pause video"
                              onClick={() => this.props.pauseVideo(i)}
                              className="icon-standard-size me-1 max-width-25 pointer"
                              src={pauseCurrent}
                            />
                          </>
                        )}
                        {this.props.varPlayerValues &&
                          this.checkPlayAllView(
                            this.props.varPlayerValues,
                            time
                          ).length > 0 && (
                            <img
                              loading="lazy"
                              src={playAllDark}
                              onClick={() => this.props.playAllTimelineVideo(i)}
                              title="Play all"
                              className="icon-standard-size me-1 max-width-24 pointer"
                            />
                          )}
                        <img
                          loading="lazy"
                          title="Pause video"
                          onClick={() => this.props.backwardVideo(i)}
                          className="icon-standard-size me-1 max-width-25 pointer"
                          src={backwardTenSec}
                        />
                        <img
                          loading="lazy"
                          title="forward 10 sec"
                          onClick={() => this.props.forwardVideo(i)}
                          className="icon-standard-size me-1 max-width-25 pointer"
                          src={forwardTenSec}
                        />
                      </>
                    ) : (
                      <>
                        <img
                          loading="lazy"
                          title="Rewind video"
                          onClick={() => this.props.rewindVideo(i)}
                          className="icon-standard-size me-1 max-width-25 pointer"
                          src={rewind_unfill}
                        />
                        <img
                          loading="lazy"
                          title="Play video"
                          onClick={() => this.props.playVideo(i)}
                          className="icon-standard-size me-1 max-width-24 pointer"
                          src={play_unfill}
                        />
                        {this.props.varPlayerValues &&
                          this.checkPlayAllView(
                            this.props.varPlayerValues,
                            time
                          ).length > 0 && (
                            <img
                              loading="lazy"
                              onClick={() => this.props.playAllTimelineVideo(i)}
                              title="Play all"
                              className="icon-standard-size me-1 max-width-24 pointer"
                              src={playAll}
                            />
                          )}
                        <img
                          loading="lazy"
                          title="Pause video"
                          onClick={() => this.props.backwardVideo(i)}
                          className="icon-standard-size me-1 max-width-25 pointer"
                          src={backwardTenSec}
                        />
                        <img
                          loading="lazy"
                          title="forward 10 sec"
                          onClick={() => this.props.forwardVideo(i)}
                          className="icon-standard-size me-1 max-width-25 pointer"
                          src={forwardTenSec}
                        />
                      </>
                    )}
                  </div>
                  <div className="col-sm-6">
                    <div id="timeline-tags" className="grid-container-event">
                      {this.props.allObjects &&
                        this.props.allObjects.map((k, v) => (
                          <div>
                            <div
                              title={k.name}
                              onClick={() =>
                                this.props.saveTimeLineObject({ k, time, i })
                              }
                              id={`${k.id}-${i}-timelineTags`}
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "245px",
                              }}
                              data-aria-label={k.id}
                              className={`${
                                highLightTags({
                                  timeline: time,
                                  timelineId: time.id,
                                  tagId: k.id,
                                  objects: this.props.allObjects,
                                })
                                  ? "grid-item grid-active "
                                  : "grid-item grid-inactive"
                              }`}
                            >
                              {k.name}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <Tooltip
                  title={
                    <div style={{ color: "white" }}>
                      {this.props.convertToHHMMssSSS(this.state.currentTime)}
                    </div>
                  }
                  placement="top"
                  arrow
                  followCursor
                  id="tool-tip"
                  PopperProps={{
                    popperRef,
                    anchorEl: {
                      getBoundingClientRect: () => {
                        if (this.positionRef && this.positionRef.current) {
                          return new DOMRect(
                            this.positionRef.current?.x || 0,
                            this.positionRef.current?.y || 0,
                            0,
                            0
                          );
                        } else {
                          return new DOMRect(0, 0, 0, 0);
                        }
                      },
                    },
                  }}
                >
                  <div
                    ref={areaRef}
                    onMouseMove={this.onMouseMove}
                    onClick={(e) =>
                      this.props.progress({
                        event: e,
                        playVideo: `${time.id}-playVideo`,
                        index: i,
                      })
                    }
                    className="timeline-playbar-div mw-100 pointer"
                  >
                    <div
                      aria-label="0"
                      style={{
                        width: "0%",
                        background: "red",
                        height: "10px",
                        position: "absolute",
                        border: "0",
                      }}
                      className="mw-100"
                      id={`${time.id}-playVideo`}
                    ></div>
                  </div>
                </Tooltip>

                <div
                  id="progress-div"
                  // onClick={(e) => {
                  //   if (e.target == e.currentTarget) {
                  //     this.props.progress({
                  //       event: e,
                  //       playVideo: `${time.id}-playVideo`,
                  //       index: i,
                  //     });
                  //   }
                  // }}
                  className="timeline-bar-div-common timeline-graphic-div mw-100"
                >
                  {this.props.varPlayerValues &&
                    this.props.varPlayerValues.map((k, v) =>
                      this.checkEvents(k, time).map((o, v) => {
                        return (
                          <HtmlTooltip
                            className="fw-bold fs-3"
                            placement="bottom"
                            title={
                              <>
                                <div style={{ color: "white" }}>
                                  {this.props.convertToHHMMssSSS(o.start_time)}
                                </div>
                                <div style={{ color: "white" }}>
                                  {o.name !== "" &&
                                  o.name !== null &&
                                  o.name !== undefined
                                    ? o.name
                                    : ""}
                                </div>
                              </>
                            }
                            arrow
                          >
                            <div
                              onClick={() => {
                                if (k) {
                                  this.props.playEventsVideo(
                                    o,
                                    i,
                                    `${time.id}-playVideo`
                                  );
                                }
                              }}
                              id={"timeline-events-play-"+o.id}
                              className="link-timeline-graphic-div"
                              style={this.props.getStyle(o)}
                            ></div>
                          </HtmlTooltip>
                        );
                      })
                    )}
                </div>
                <div id={`${i}-time`} className="float-right p-0">
                  {this.props.convertToHHMMssSSS(0)} /{" "}
                  {this.props.convertToHHMMssSSS(this.props.totalDuration)}
                </div>
              </div>
            </>
          ))}
        {!this.props.share && (
          <>
            <img
              loading="lazy"
              onClick={() => this.props.addTimeLine()}
              src={addTimeline}
              className="icon-standard-size pointer"
              title="Add timeline"
              alt=""
            />
          </>
        )}
      </div>
    );
  }


  checkPlayAllView = (data, timeline) => {
    let subsCribedtags = this.IsExists(timeline.subscribedTags)
      ? timeline.subscribedTags.split(",")
      : [];
    let filteredEvents = this.props.allEvents.filter((d) => {
      let status = true;
      let tags = this.IsExists(d.tag_ids) ? d.tag_ids.split(",") : [];
      subsCribedtags.forEach((d) => {
        if (!tags.includes(d)) {
          status = false;
        }
      });
      if (status) {
        return d;
      }
    });

    return filteredEvents;
  };

  onMouseMove = (event) => {
    this.positionRef.current = { x: event.clientX, y: event.clientY - 5 };
    this.showTimeForCursorPosition(event);
    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };
  getnewTime = (e, btn) => {
    if (e.nativeEvent.offsetX <= btn.clientWidth)
      return (
        (e.nativeEvent.offsetX / btn.clientWidth) * this.props.totalDuration
      );
  };
  showTimeForCursorPosition = (event) => {
    // click anywhere in the timeline place progress bar to that place
    const btn = document.getElementById("progress-div");
    if (btn !== undefined && btn !== null) {
      let currentTime = this.getnewTime(event, btn);
      this.setState({
        currentTime: currentTime,
      });
    }
  };
  IsExists = (data) => {
    if (data === null || data === undefined || data === "") {
      return false;
    } else {
      return true;
    }
  };
  updateTimelineDetails = (element) => {
    let btn = document.getElementById(`${element.id}-timeline-name`);
    if (
      btn.textContent !== "" &&
      btn.textContent !== null &&
      btn.textContent !== undefined
    ) {
      let obj = {
        timelineName: btn.textContent,
        id: element.id,
      };

      VideoService.updateTimelineDetails(obj)
        .then((response) => {})
        .catch((err) => console.error("timeline update error"));
    } else {
      btn.innerHTML = element.name;
    }
  };
  checkEvents = (events, timeline) => {
    let func = "or";
    let subsCribedtags = this.IsExists(timeline.subscribedTags)
      ? timeline.subscribedTags.split(",")
      : [];

    let arrayOfTags = this.props.allObjects.map((val) => val.id);
    subsCribedtags = subsCribedtags.filter((tags) =>
      arrayOfTags.includes(+tags)
    );

    if (subsCribedtags.length === 0) {
      return this.props.allEvents;
    }
    let filteredEvents = [];
    if (func === "or") {
      filteredEvents = this.props.allEvents.filter((d) => {
        let status = true;
        let tags = this.IsExists(d.tag_ids) ? d.tag_ids.split(",") : [];
        subsCribedtags.forEach((d) => {
          if (!tags.includes(d)) {
            status = false;
          }
        });
        if (status) {
          return d;
        }
      });
    }
    if (func === "and") {
      let subscribed_tags = subsCribedtags.sort().join();
      filteredEvents = this.props.allEvents.filter((d) => {
        let tagId = this.IsExists(d.tag_ids)
          ? d.tag_ids.split(",").sort().join()
          : "";
        if (subscribed_tags === tagId) {
          return d;
        }
      });
    }

    return filteredEvents;
  };
}
export default React.memo(Timeline);

import React, { Component } from "react";
import {
  BrowserRouter as Router
} from "react-router-dom";
import NavigationRouter from "./NavigationRouter.jsx";

class Navigations extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <Router basename="/VideoTagging">
        <NavigationRouter />
      </Router>
    );
  }
}
export default Navigations;



import ManageUserService from "../../api/ManageUserService";
import UserMappingService from "../../api/UserMappingService";
import {
  GET_ALL_FILTERED_USERS,
  GET_ALL_LEAD_PARTNER_DETAILS,
  GET_ALL_USERS_BY_ID,
  GET_ALL_USER_DETAILS,
} from "../types";

export const getAllUserDetails = (a) => async (dispatch) => {
  try {
    var obj = {
      pageNo: a.page,
      search: a.search,
    };
    ManageUserService.getUserList(obj)
      .then((response) => {
        if (response.data && response.data.data) {
          dispatch({
            type: GET_ALL_USER_DETAILS,
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {});
  } catch (error) {
    dispatch({
      type: GET_ALL_USER_DETAILS,
      payload: [],
    });
  }
  return [];
};

export const getAllusersById = (a) => async (dispatch) => {
  let obj = {
    search: a.a,
  };

  try {
    UserMappingService.getAllusersName(obj)
      .then((response) => {
        if (response.data && response.data.data) {
          dispatch({
            type: GET_ALL_USERS_BY_ID,
            payload: response.data.data.data,
          });
        }
      })
      .catch((error) => {});
  } catch (error) {
    dispatch({
      type: GET_ALL_USERS_BY_ID,
      payload: [],
    });
  }
};

export const filterUserList = (a) => async (dispatch) => {
  try {
    var obj = {
      user_type: a.user_type,
      branch_id: a.branch_id,
      city_id: a.city_id,
    };
    // ManageUserService.filterUser(obj)
    //   .then((response) => {
    //

    //     if (response.data && response.data.data) {
    //       dispatch({
    //         type: GET_ALL_FILTERED_USERS,
    //         payload: response.data.data,
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //
    //   });
  } catch (error) {
    dispatch({
      type: GET_ALL_FILTERED_USERS,
      payload: [],
    });
  }
  return [];
};

export const getAllLeadPartnerDetails = (a) => async (dispatch) => {
  try {
    var obj = {
      pageNo: a.page,
      search: a.search,
    };
    ManageUserService.getLeadPartnerList(obj)
      .then((response) => {
        if (response.data && response.data.data) {
          dispatch({
            type: GET_ALL_LEAD_PARTNER_DETAILS,
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {});
  } catch (error) {
    dispatch({
      type: GET_ALL_LEAD_PARTNER_DETAILS,
      payload: [],
    });
  }
  return [];
};

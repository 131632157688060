import CryptoJS from "crypto-js";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Swal from "sweetalert2";
import LoginService from "../../api/LoginService";
import logo from "../../images/video-black-on-white.png";
import AuthService from "../../session/AuthService";
import "./Login.css";

var Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
var IPdress = "";
class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      hasLoginFailed: false,
      message: false,
      langValue: "en",
      Forget: false,
      isSignUp: false,
      showPassword: "password",
    };

    this.handleForm = this.handleForm.bind(this);
    this.loginClick = this.loginClick.bind(this);
    this.handleLoginPress = this.handleLoginPress.bind(this);
  }
  onOpenSignUp = () => {
    this.setState({
      isSignUp: !this.state.isSignUp,
    });
  };
  componentDidMount() {
    user_location();
    if (AuthService.isAuth()) {
      if (AuthService.getUserType() === "2") {
        this.props.history.push(`/video-tagging`);
      } else {
        this.props.history.push(`/add-users`);
      }
    }
  }

  render() {
    const { isSignUp, Forget } = this.state;

    return (
      <div>
        <section className="vh-100" style={{ backgroundColor: "#9A616D" }}>
          <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12 col-md-8 col-lg-6 col-xl-6">
                <div className="card shadow-2-strong" style={{ borderRadius: "1rem" }}>
                  <div className="card-body p-5">
                    <form>
                      <div class="d-flex align-items-center mb-3 pb-1">
                        <img
                          style={{ width: "30%" }}
                          src={logo}
                          alt="login form"
                          loading="lazy"
                        />
                        <span class="h1 fw-bold mb-0"></span>
                      </div>

                      <h5
                        class="fw-normal text-center mb-3 pb-3"
                        style={{ letterSpacing: "1px" }}
                      >
                        Sign in to start your session
                      </h5>
                      <div className="pb-3">
                        <p style={{ fontSize: "medium" }}>Email Address</p>
                        <div className="input-group mb-3">
                          <span className="input-group-text">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                            </svg>
                          </span>
                          <input
                            type="text"
                            onInput={this.handleForm}
                            onKeyPress={(e) => this.handleLoginPress(e)}
                            className="form-control form-control-lg"
                            value={this.state.email}
                            autoComplete="off"
                            placeholder="Jhon@gmail.com"
                            name="email"
                          />
                        </div>

                        <p style={{ fontSize: "medium" }}>Password</p>
                        <div className="input-group mb-3">
                          <span className="input-group-text" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-key" viewBox="0 0 16 16">
                              <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
                              <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                          </span>
                          <input
                            type="password"
                            onInput={this.handleForm}
                            onKeyPress={(e) => this.handleLoginPress(e)}
                            className="form-control form-control-lg"
                            value={this.state.password}
                            placeholder="**********"
                            autoComplete="off"
                            name="password"
                          />
                        </div>

                        <div className="pt-1 mb-4">
                          <button style={{ float: "right" }}
                            class="btn btn-dark btn-lg btn-block float-right w-50 "
                            onClick={(e) => this.loginClick(e)}
                            type="button"
                          >
                            Login
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    );
  }

  handleLoginPress(e) {
    if (e.key === "Enter") {
      this.loginClick();
    }
  }
  handleForm(event) {

    this.setState({
      [event.target.name]: event.target.value,
    }, () => {

    });
  }

  loginClick(e) {


    if (!this.state.email) {
      // Toast.fire({
      //   icon: "warning",
      //   title: "Please enter a valid email",
      // });
    } else if (!this.state.password) {
      // Toast.fire({
      //   icon: "warning",
      //   title: "Please enter a valid password",
      // });
    } else {
      var navi =
        navigator.userAgent +
        " & " +
        navigator.vendor +
        " & " +
        navigator.language;
      var encript = CryptoJS.MD5(this.state.password).toString();
      var loginUser = {
        mailID: this.state.email,
        password: encript,
        SystemDetails: navi,
        ipAddress: IPdress,
        navication: navi,
      };

      LoginService.getLogin(loginUser)
        .then((response) => {
          var result = response.data;




          if (response.data.status === 1) {

            AuthService.registerAuth(this.state.username, result.data);
            AuthService.storeIpAddress(IPdress);
            AuthService.registerToken(result.data.token);

            if (AuthService.getUserType() === "2") {
              this.props.history.push(`/video-tagging`);
            } else {
              this.props.history.push(`/add-users`);
            }
          } else if (response.data.status === -1) {
            Toast.fire({
              icon: "error",
              title: "Email OR Password Is Incorrect",
            });
          } else {
            Toast.fire({
              icon: "error",
              title: "Please try with valid credentials",
            });
          }
        })
        .catch((error) => {

          Toast.fire({
            icon: "error",
            title: "Network error, Please try again later",
          });
          this.setState({ message: false });
          this.setState({ hasLoginFailed: true });
        });
    }
  }
}

function user_location() {
  var xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200) {

      IPdress = this.responseText;
    }
  };
  xhttp.open("GET", "//api.ipify.org?format=json", true);
  xhttp.send();
}

const mapStateToProps = function (state) {

  return {
  };
};
const mapDispacthToProps = (dispatch) => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withRouter(LoginContainer));

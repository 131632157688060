import React, { Component } from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import "react-edit-text/dist/index.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Swal from "sweetalert2";
import VideoService from "../../api/VideoService";
import createVideo from "../../images/add-VAD.png";
import userIcon from "../../images/user_icon.jpg";
import { getSelectedVideos } from "../../redux/actions/VideoAction";
import AuthService from "../../session/AuthService";
import videoClone from "../../vImages/clone-VAD.svg";
import PopularVideoGrid from "../MostPopularVideo/PopularVideoGrid";
import SharedVideoGrid from "../SharedVideo/SharedVideoGrid";
import ViewEditVideoGrid from "../ViewEditedVideo/ViewEditVideoGrid";

let data = [];
data.push(0);
data.push(0);
data.push(0);
data.push(0);

const SwalWithUnbounce = Swal.mixin({
  showClass: {
    popup: "animate__animated animate__zoomIn", // Add your preferred unbounce animation class here
  },
  hideClass: {
    popup: "animate__animated animate__zoomOut", // Add your preferred exit animation class here
  },
});
class VideoGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuState: false,
      opendeleteStaffDialogue: false,
      openeditStaffDialogue: false,
      obj: {},
      showPlusMenu: false,
      search: "",
      blocking: false,
      enableEdit: false,
      editIndex: null,
      viewedVideo: [],
      sharedVideo: [],
      popularVideo: [],
      onBlurEvent: false,
      showTitleCard: false,
    };
    this.wrapperRef = React.createRef();
    this.textRefs = {};
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  render() {
    const { newVideo, data, enableCheckBox, cloneVideo, changeToVideo } =
      this.props;
    const logout = () => {
      AuthService.removeAuth();
      this.props.history.push(`/`);
    };

    return (
      <>
        <BlockUi
          tag="div"
          blocking={this.state.blocking}
          message={"Loading, please wait"}
          keepInView
          color="#60c2e7"
        >
          <div className="manageTeamGridWrapper">
            <div className="titleMdWrapper">
              <div className="titleMd label">
                MY ANNOTATION DOCUMENTS
                <img
                  className="float-right margin-5"
                  onClick={this.logOut}
                  title="logout"
                  style={{ maxWidth: "35px", cursor: "pointer" }}
                  src={userIcon}
                />
                <img
                  loading="lazy"
                  className="float-right"
                  title="clone video"
                  style={{
                    cursor: "pointer",
                    maxWidth: "35px",
                    marginRight: "5px",
                  }}
                  onClick={() => cloneVideo()}
                  src={videoClone}
                />
                <img
                  loading="lazy"
                  className="float-right"
                  title="create video"
                  style={{
                    cursor: "pointer",
                    maxWidth: "35px",
                    marginRight: "5px",
                  }}
                  onClick={() => newVideo()}
                  src={createVideo}
                />
                <div className="float-right">
                  Logged in as{" "}
                  <a
                    style={{
                      color: "#1B56A5",
                      marginRight: "15px",
                      lineHeight: "1.2",
                    }}
                  >
                    {AuthService.getUserName()}
                  </a>
                </div>
              </div>
              <hr className="hr-newline" />
            </div>
            <div>
              <div className="d-flex flex-wrap align-items-center">
                {data &&
                  data.map((element, index) => (
                    <div className="card-image">
                      <img
                        className="image-card"
                        onClick={() => this.handleClick(element)}
                        frameBorder="0"
                        width="340"
                        height=""
                        loading="lazy"
                        src={`http://img.youtube.com/vi/${element.link}/mqdefault.jpg`}
                      />
                      <>
                        <div className="d-flex align-items-center" key={index}>
                          {/* <div className="video-title"> */}
                          <div
                            ref={(ref) => (this.textRefs[index] = ref)}
                            style={{
                              outline:
                                this.state.enableEdit === true &&
                                this.state.editIndex === index
                                  ? "1px solid black"
                                  : "none",
                              fontWeight: "bold",
                              fontSize: "large",
                              minHeight: "0px",
                              textOverflow:
                                this.state.showTitleCard &&
                                this.state.editIndex === index
                                  ? "clip"
                                  : "ellipsis",
                              textAlign:
                                this.state.showTitleCard &&
                                this.state.editIndex === index
                                  ? "start"
                                  : "inherit",
                            }}
                            id={element.id}
                            onFocus={() =>
                              this.setState({
                                showTitleCard: true,
                                editIndex: index,
                                enableEdit: true,
                              })
                            }
                            onBlur={() => this.handleBlur(element, index)}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                this.updateVideoDetails(element);
                              }
                            }}
                            className="video-title"
                            contenteditable="true"
                            dangerouslySetInnerHTML={{ __html: element.name }} // Render the HTML content
                          ></div>
                          {/* </div> */}
                          {this.checkAccess(element) && (
                            <>
                              <span
                                title="Delete video"
                                className="pointer"
                                onClick={() => this.removeImage(element.id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20px"
                                  height="20px"
                                  fill="currentColor"
                                  class="bi bi-trash3-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                </svg>
                              </span>
                            </>
                          )}
                        </div>
                        <div className="nowrap video-details">
                          {`${element.created_date.split(" ")[0]}`}&nbsp;&nbsp;
                          {`-`}&nbsp;&nbsp;
                          {element &&
                            element.totalDuration !== "" &&
                            element.totalDuration !== null &&
                            element.totalDuration}
                          &nbsp;&nbsp;{`-`}&nbsp;&nbsp;
                          {element.events.length + " events"}
                        </div>
                        {enableCheckBox && (
                          <input
                            onChange={(e) => changeToVideo(e, element)}
                            type="checkbox"
                          />
                        )}
                      </>
                    </div>
                  ))}
              </div>
            </div>
            {!this.props.data[0] && (
              <>
                <div className="ps-4 pt-4">{"None yet"}</div>
              </>
            )}
            {this.state.showPlusMenu && (
              <>
                <div>
                  <ul className="dropdownCard" ref={this.wrapperRef}>
                    <li className="user-header">
                      <div>
                        <span style={{ color: "white" }}>
                          {AuthService.getUserName()}
                        </span>
                        <br />
                        <small style={{ color: "white" }}>
                          {AuthService.getAuthMailId()}
                        </small>
                      </div>
                    </li>
                    <div className="user_footer">
                      <div className="row user_info">
                        <div className="col-md-8"></div>
                        <div className="col-md-4">
                          <a
                            onClick={logout}
                            id="sign_out"
                            className="btn btn-default btn-flat"
                          >
                            Sign out
                          </a>
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </>
            )}
          </div>
          <div className="manageTeamGridWrapper">
            <div className="titleMdWrapper">
              <h3 className="titleMd uppercase label">shared with you</h3>
              <hr className="hr-newline" />
            </div>
            <div>
              <div className="d-flex flex-wrap align-items-center">
                <SharedVideoGrid
                  checkAccess={(a) => this.checkAccess(a)}
                  updateDetails={(a, name) =>
                    this.updateSharedVideoDetails(a, name)
                  }
                  showVideo={(a) => this.showVideo(a)}
                  loadMostPopularVideo={this.loadMostPopularVideo}
                  loadViewedVideo={this.loadViewedVideo}
                  loadSharedData={this.loadSharedData}
                  sharedVideo={this.state.sharedVideo}
                />
              </div>
            </div>
          </div>
          <div className="manageTeamGridWrapper">
            <div className="titleMdWrapper">
              <h3 className="titleMd uppercase label">
                Viewed Or Edited By You
              </h3>
              <hr className="hr-newline" />
            </div>
            <div>
              <div className="d-flex flex-wrap align-items-center">
                <ViewEditVideoGrid
                  checkAccess={(a) => this.checkAccess(a)}
                  updateDetails={(a) => this.updateVideoDetails(a)}
                  showVideo={(a) => this.showVideo(a)}
                  loadMostPopularVideo={this.loadMostPopularVideo}
                  loadViewedVideo={this.loadViewedVideo}
                  loadSharedData={this.loadSharedData}
                  viewedVideo={this.state.viewedVideo}
                />
              </div>
            </div>
          </div>
          <div className="manageTeamGridWrapper">
            <div className="titleMdWrapper">
              <h3 className="titleMd uppercase label">Most Popular</h3>
              <hr className="hr-newline" />
            </div>
            <div className="d-flex flex-wrap align-items-center">
              <PopularVideoGrid
                checkAccess={(a) => this.checkAccess(a)}
                updateDetails={(a, name) =>
                  this.updatePopularVideoDetails(a, name)
                }
                showVideo={(a) => this.showVideo(a)}
                loadMostPopularVideo={this.loadMostPopularVideo}
                loadViewedVideo={this.loadViewedVideo}
                loadSharedData={this.loadSharedData}
                popularVideo={this.state.popularVideo}
                getAllVideo={() => this.props.getAllVideo()}
              />
            </div>
          </div>
        </BlockUi>
      </>
    );
  }

  handleBlur = (element, index) => {
    this.textRefs[index].scrollLeft = 0;
    this.setState(
      {
        enableEdit: false,
        editIndex: null,
        onBlurEvent: true,
        showTitleCard: false,
        enableEdit: false
      },
      () => {
        // Wait for onBlur event to finish before enabling onclick action
        setTimeout(() => {
          this.updateVideoDetails(element);
          this.setState({ onBlurEvent: false });
        }, 0);
      }
    );
  };

  handleClick = (element) => {
    if (!this.state.onBlurEvent) {
      this.showVideo(element);
    }
  };

  loadViewedVideo = () => {
    VideoService.getAllViewedVideos({
      user_id: parseInt(AuthService.getUserId()),
    }).then((response) => {
      this.setState({
        viewedVideo: response.data.data.data,
      });
    });
  };

  loadSharedData = () => {
    let obj = {
      userId: AuthService.getUserId(),
    };
    VideoService.getAllSharedVideos(obj).then((response) => {
      this.setState({
        sharedVideo: response.data.data,
      });
    });
  };

  loadMostPopularVideo = () => {
    VideoService.getAllPopularVideos({}).then((response) => {
      this.setState(
        {
          popularVideo: response.data.data,
        },
        () => {}
      );
    });
  };

  checkAccess = (element) => {
    let access = element.access;

    let boolean = false;
    if (access && access.length > 0) {
      if (parseInt(element.ownerId) !== parseInt(AuthService.getUserId())) {
        if (access && access[0] && access[0].access) {
          boolean = access[0].access;
        }
      }

      let options = access.filter(
        (a) => parseInt(a.userId) === parseInt(AuthService.getUserId())
      );
      if (options && options.length > 0) {
        let extraAccess = options[0];
        if (extraAccess && extraAccess.access) {
          boolean = extraAccess.access;
        }
      }
    }

    if (
      element &&
      element.ownerId &&
      parseInt(element.ownerId) === parseInt(AuthService.getUserId())
    ) {
      boolean = true;
    }

    return boolean;
  };
  cloneVideo = () => {
    this.setState({ enableCheckBox: true });
  };
  updateVideoDetails = async (element) => {
    let btn = document.getElementById(element.id);

    if (
      btn.textContent !== "" &&
      btn.textContent !== null &&
      btn.textContent !== undefined
    ) {
      let obj = {
        name: btn.textContent.trim(),
        id: element.id,
      };

      await VideoService.updateVideoDetails(obj).then((response) => {
        if (response.data.status === 1) {
          // await this.props.getAllVideo();
        }
      });
    } else {
      btn.innerHTML = element.name;
    }
  };

  updateSharedVideoDetails = (element, name) => {
    let btn = document.getElementById("SharedVideo-" + element.id);

    if (
      btn.textContent !== "" &&
      btn.textContent !== null &&
      btn.textContent !== undefined
    ) {
      let obj = {
        name: btn.textContent.trim(),
        id: element.id,
      };

      VideoService.updateVideoDetails(obj).then(async (response) => {
        if (response.data.status === 1) {
          await this.props.getAllVideo();
        }
      });
    } else {
      btn.innerHTML = element.name;
    }
  };

  updatePopularVideoDetails = (element, name) => {
    let btn = document.getElementById("PopularVideo-" + element.id);

    if (
      btn.textContent !== "" &&
      btn.textContent !== null &&
      btn.textContent !== undefined
    ) {
      let obj = {
        name: btn.textContent.trim(),
        id: element.id,
      };

      VideoService.updateVideoDetails(obj).then(async (response) => {
        if (response.data.status === 1) {
          await this.props.getAllVideo();
        }
      });
    } else {
      btn.innerHTML = element.name;
    }
  };

  removeImage = (id) => {
    SwalWithUnbounce.fire({
      html: "<div class='delete-content'>Deleting this annotation document will delete ALL of the events and tags for this document, not just the events and tags you have created.<div>",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
      customClass: {
        container: "swal-top-center",
      },
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (id !== "" && id !== 0) {
          let obj = {
            id: id,
          };
          this.setState({ blocking: true });
          VideoService.deleteVideo(obj).then((response) => {
            if (response.data.status === 1) {
              this.setState({ blocking: false });
              this.props.getAllVideo();
            }
          });
        }

        SwalWithUnbounce.fire(
          "Deleted!",
          "Your video has been deleted.",
          "success"
        );
      }
    });
  };
  handleClickOutside = (event) => {
    if (
      this.wrapperRef &&
      this.wrapperRef.current != null &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({
        showPlusMenu: false,
      });
    }
  };
  logOut = (event) => {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.setState({
      showPlusMenu: !this.state.showPlusMenu,
    });
  };
  onSearch = (e) => {
    this.setState({
      search: e.target.value,
    });
    this.props.search(e.target.value);
  };
  showVideo = (element) => {
    this.props.videoChange(element);
  };
}
const mapStateToProps = function (state) {
  return {
    userlist: state.manageUser.userlist,
    loader: state.video.loading,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getSelectedVideos: (a) => dispatch(getSelectedVideos(a)),
  };
};
export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withRouter(VideoGrid));

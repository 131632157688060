import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import AuthRoute from "../app/AuthRoute.jsx";
import Topbar from "../components/Topbar.jsx";
import Login from "../screens/Login/LoginContainer";
import UserContainer from "../screens/ManageUser/UserContainer.jsx";
import ShareConatiner from "../screens/Share/ShareConatiner.jsx";
import VideoContainer from "../screens/VideoTagging/VideoContainer.jsx";

class NavigationRouter extends Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact>
          <Login />
        </Route>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/login" exact>
          <Login />
        </Route>
        <AuthRoute path="/video-tagging" exact>
          <Container fluid className="appContainer">
            <VideoContainer />
          </Container>
        </AuthRoute>
        <Route path="/share" exact>
          <ShareConatiner />
        </Route>
        <AuthRoute path="/add-users" exact>
          <div className="content-page">
            <Topbar />
            <UserContainer />
          </div>
        </AuthRoute>
        <Route path="*">
          <Login />
        </Route>
      </Switch>
    );
  }
}
export default withRouter(NavigationRouter);

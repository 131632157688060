
import AuthService from "../session/AuthService";

class http {
    HEADERS() {
        return {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': 'JWT fefege...'
        };
    }
    AUTH_HEADERS() {
        return {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': AuthService.getToken(),
            // 'Client-Ip': AuthService.getIpAddress()
        }
    }
}
export default new http();

export const GET_ALL_USER_DETAILS = "GET_ALL_USER_DETAILS";
export const GET_ALL_LEAD_PARTNER_DETAILS = "GET_ALL_LEAD_PARTNER_DETAILS";
export const GET_ACTIVE_USER_COUNT = "GET_ACTIVE_USER_COUNT";
export const GET_ALL_USERS_BY_TYPE = "GET_ALL_USERS_BY_TYPE";
export const GET_ALL_FILTERED_USERS = "GET_ALL_FILTERED_USERS";
export const GET_ALL_USERS_BY_ID = "GET_ALL_USERS_BY_ID";
export const GET_ALL_VIDEOS_DETAILS = "GET_ALL_VIDEOS_DETAILS";
export const GET_ALL_SHARED_VIDEOS = "GET_ALL_SHARED_VIDEOS";
export const GET_ALL_VIEW_VIDEOS = "GET_ALL_VIEW_VIDEOS";
export const GET_ALL_MOST_POPULAR_VIDEOS = "GET_ALL_MOST_POPULAR_VIDEOS";
export const GET_SELECTED_VIDEOS = "GET_SELECTED_VIDEOS";

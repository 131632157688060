import CircularProgress from "@material-ui/core/CircularProgress";
import React, { Component } from "react";
import BlockUi from "react-block-ui";
import 'react-block-ui/style.css';
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Swal from "sweetalert2";
import VideoService from "../../api/VideoService";
import Topbar from "../../components/Topbar";
import { youtubeKey } from "../../config/app-config";
import {
  emptyReduxData, getAllVideoDetails,
  getSelectedVideos
} from "../../redux/actions/VideoAction.js";
import AuthService from "../../session/AuthService";
import NewUserDialog from "./NewVideo.jsx";
import Grid from "./VideoGrid";
import './VideoGrid.css';

var page = 0;
function IsExists(mj) {
  if (mj === null || mj === undefined || mj === "") {
    return false;
  }
  return true;
}
function convertISO3601DurationToString(ISO3601Duration) {
  var string = ISO3601Duration.substring(2);
  return string.replace("DT", "days ").replace("H", "hr ").replace("M", "m ").replace("S", "s");
}


const SwalWithUnbounce = Swal.mixin({
  showClass: {
    popup: 'animate__animated animate__zoomIn', // Add your preferred unbounce animation class here
  },
  hideClass: {
    popup: 'animate__animated animate__zoomOut', // Add your preferred exit animation class here
  },
});
class VideoContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openVideo: false,
      userlist: [],
      activePage: 1,
      loaded: false,
      viewVideo: false,
      search: "",
      youtubeURL: "",
      enableCheckBox: false,
      video: [],
      buttonShow: true,
      videoData: [],
      blocking: false,
      lockButton: "lock",
      option: "new",
      lock: false,
      tagMaster: [],
      timelineLength: 0,
      videoDetails: [],
      cloneData: {},
      timelineDetails: [],
      settingsObject: {
        event_name_prefix: "Event",
        event_start_time_offset: 0,
        event_default_length: 10,
        event_default_color: "#0F84BC",
        large_time_increment: 1,
        small_time_increment: 0.033333,
        timeline_name_prefix: "Timeline"
      }
    };
  }
  componentDidMount() {
    this.props.getAllVideoDetails({ userId: AuthService.getUserId() });
  }
  render() {
    return (
      <>
        <BlockUi tag="div" blocking={this.state.blocking} message={'Loading, please wait'} keepInView color="#60c2e7" >
          {!this.state.openVideo && (
            <>
              <table className="w-100">
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ paddingLeft: 0 }}
                  className="column"
                >
                    {!this.state.viewVideo && (
                      <Grid
                        videoChange={(a) => this.videoChange(a)}
                        data={this.props.videoDetails}
                        getAllVideo={() => this.getAllVideo()}
                        getAllVideoAfterSaveTitle={() => this.getAllVideoAfterSaveTitle()}
                        newVideo={(a) => this.onToggleNewStaffDialog(a)}
                        loding={this.props.loader}
                        enableCheckBox={this.state.enableCheckBox}
                        changeToVideo={(a, b) => this.changeToVideo(a, b)}
                        cloneVideo={() => this.cloneVideo()}
                      />
                    )}
                  {this.props.loader && this.props.videoDetails.length == 0 && (
                    <CircularProgress
                      style={{ left: "47%", position: "absolute", top: "45%" }}
                      size={50}
                    />
                  )}
                </Col>
              </table>
            </>
          )}
          {this.state.openVideo && (
            <>
              <NewUserDialog
                option={this.state.option}
                url={this.state.youtubeURL}
                videoData={this.state.videoData}
                selectedVideo={this.props.selectedVideo}
                loading={this.props.loading}
                lock={this.state.lock}
                timelineLength={this.state.timelineLength}
                timelineDetails={this.state.timelineDetails}
                back={this.backHome}
                loadBack={(a) => this.loadBack(a)}
              />
              <Topbar deleteVideo={this.deleteVideo} back={this.backHome} lockChange={this.lockChange} lockButton={this.state.lockButton} />
            </>
          )}
        </BlockUi>
      </>
    );
  }
  changeToVideo = (event, value) => {
    if (event.target.checked) {
      this.setState({ cloneData: value }, () => {
        this.onToggleNewStaffDialog()
      })
    }
  }
  cloneVideo = () => {
    this.setState({
      enableCheckBox: !this.state.enableCheckBox
    })
  }
  lockChange = () => {
    this.setState({ lock: !this.state.lock, openVideo: true }, () => {
      if (this.state.lock) {
        this.setState({
          lockButton: "unlock"
        })
      } else {
        this.setState({
          lockButton: "lock"
        })
      }
    })
  }
  deleteVideo = () => {

    SwalWithUnbounce.fire({
      html: "<div class='delete-content'>Deleting this annotation document will delete ALL of the events and tags for this document, not just the events and tags you have created.<div>",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
      customClass: {
        container: 'swal-top-center'
      },
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (this.state.lock === false) {
          let obj = {
            id: this.state.videoData.id
          };
          
          try {
            const response = await VideoService.deleteVideo(obj);
            if (response.data.status === 1) {
              await SwalWithUnbounce.fire("Deleted!", "Your video has been deleted.", "success");
              
              this.backHome();
            }
          } catch (error) {
            // Handle error if the deleteVideo request fails
            console.error(error);
          }
        }
      }
    });
  }

  loadBack = (a) => {
    this.props.getSelectedVideos({ id: a });
  }

  getAllVideo = () => {
    this.setState({
      newVideo: false,
      openVideo: false,
    })
    this.props.getAllVideoDetails({ userId: AuthService.getUserId() });
  };
  videoChange = (a) => {
    this.props.getSelectedVideos({ id: a.id });
    let data = [];
    data.push(a);
    let url = `https://www.youtube.com/watch?v=${data[0].link}`;
    this.setState({
      timelineDetails: [],
      openVideo: !this.state.openVideo,
      buttonShow: false,
      viewVideo: false,
      youtubeURL: url,
      option: "edit",
      videoData: a,
    });
  };
  backHome = () => {
    document.getElementById("topbar-button-content").style.display = "none";
    this.setState({
      buttonShow: true,
      openVideo: false,
      viewVideo: false,
      lock: false,
      enableCheckBox : false,
      lockButton: "lock"
    }, () => {
      Swal.close();
      this.props.getAllVideoDetails({ userId: AuthService.getUserId() });
      this.props.emptyReduxData();
    });
  };
  onToggleNewStaffDialog = (a) => {
    SwalWithUnbounce.fire({
      title: "Create video annotation document",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
        placeholder: "Enter Youtube Video URL",
        onkeypress: "return /^[a-zA-Z0-9_ ]*$/i.test(event.key)",
      },
      showCancelButton: true,
      confirmButtonText: "Proceed",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: (data) => {
        if (!IsExists(data)) {
          SwalWithUnbounce.showValidationMessage("Please Enter URL");
          return false;
        } else {
          var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
          var match = data.match(regExp);
          if (match && match[7].length == 11) {
            var b = match[7];
            this.getVideoDetails(b);
          }
          else {
            SwalWithUnbounce.showValidationMessage("Please Enter Valid Youtube URL");
          }
        }
      },
    }).then((result) => {
      if (result.isDismissed) {
        this.setState({ enableCheckBox: false })
      }
    });
  };
  getVideoDetails(data) {
    fetch(
      `https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails&id=${data}&key=${youtubeKey}`)
      .then((res) => {
        if (res.ok) {
          return res.json().then((json) => {
            if (Object.keys(json).length > 0) {
              this.saveVideo(json);
            }
          })
        }
        Promise.reject(res);
        throw new Error("Fetch Error Please try again!!!!!")
      })
  }
  saveVideo = (obj) => {
    let form = this.state.settingsObject;
    let timelineLength = this.state.timelineLength + 1;
    form["link"] = obj.items[0].id;
    form["totalDuration"] = convertISO3601DurationToString(obj.items[0].contentDetails.duration);
    form["name"] = obj.items[0].snippet.title;
    form["ownerId"] = AuthService.getUserId();
    form["timelineLength"] = timelineLength;
    form["timelineName"] = "Timeline 1";
    form["cloneData"] = this.state.cloneData;

    VideoService.saveVideoLink(form).then(async (response) => {
      if (response.data.status === 1) {
        let result = response.data.data, array = [];
        result.forEach(async (k, v) => {
          array.push(k.timeline)
          this.setState({
            openVideo: true,
            viewVideo: !this.state.viewVideo,
            videoData: k.videoDetails,
            option: "new",
            timelineDetails: array,
            timelineLength: timelineLength
          }, () => {
            this.props.getSelectedVideos({ id: this.state.videoData.id });
          })
        })
      }
    });
  }
}

const mapStateToProps = function (state) {
  return {
    loader: state.video.loading,
    videoDetails: state.video.videoDetails,
    selectedVideo: state.video.selectedVideo,
    sharedDetails: state.video.sharedDetails,
    loading: state.video.loading,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getAllVideoDetails: (a) => dispatch(getAllVideoDetails(a)),
    getSelectedVideos: (a) => dispatch(getSelectedVideos(a)),
    emptyReduxData: () => dispatch(emptyReduxData())
  };
};

export default connect(mapStateToProps, mapDispacthToProps)(withRouter(VideoContainer));

import React, { Component } from "react";
import { withRouter } from "react-router";
import backImage from "../../src/vImages/back_arrow.svg";
import deleteIcon from "../../src/vImages/delete-VAD.svg";
// import lockImage from "../../src/vImages/lock-closed.svg";
// import lockOpen from "../../src/vImages/lock-open.svg";
import EyeIcon from '../icons/eyeIcon';
import EyeSlashIcon from '../icons/eyeSlashIcon';
import AuthService from "../session/AuthService";



class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPlusMenu: false,
    };
  }
  render() {


    const auth = AuthService.isAuth();
    const authType = AuthService.getUserType();
    return (
      <>
        {auth && (
          <div className="topbar-wrapper">
            <div className="">
              <div>
              </div>
              <div id="topbar-button-content" className="topbar-inner">
                {AuthService.getUserType() === "2" && <>
                  <div className="ms-3">
                    <img title="Back to home" className="pointer" onClick={this.props.back} style={{ maxWidth: "30px" }} src={backImage} />
                  </div>
                </>}
                <div className="toolbar-flex">
                  <div style={{ display: "contents" }}>
                    {AuthService.getUserType() === "2" && <>
                      {/* <img title={`${this.props.lockButton === "lock" ? "Lock" : "Unlock"}`} onClick={this.props.lockChange} style={{ maxWidth: "30px", cursor: "pointer", marginBottom: "10px", marginRight: "15px" }} src={`${this.props.lockButton === "lock" ? <EyeIcon /> : <EyeSlashIcon />}`} /> */}
                      {this.props.lockButton === "lock" ? <EyeIcon onClick={this.props.lockChange} /> : <EyeSlashIcon onClick={this.props.lockChange} />}
                      <img title="Delete video" className="me-3 float-right pointer" onClick={this.props.deleteVideo} src={deleteIcon} style={{ maxWidth: "30px" }} alt="" />
                    </>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      </>
    );
  }

}
export default withRouter(Topbar);

import axios from 'axios';
import * as APPCONFIG from '../config/app-config.js';

const axiosApi = axios.create({
    headers: APPCONFIG.HTTP_HEADERS,
    baseURL: APPCONFIG.API_URL
});


axiosApi.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log(error)
    switch (error.response.status) {
        case 503:
            break
        default:
            break
    }
    return Promise.reject(error);
});

export default axiosApi;
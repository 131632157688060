import React, { Component } from 'react';
import { connect } from 'react-redux';
import VideoService from '../../api/VideoService';
import { getSelectedVideos } from '../../redux/actions/VideoAction';
import NewVideo from '../VideoTagging/NewVideo';

class ShareConatiner extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        let urlKey = urlParams.get("search");
        urlKey = atob(urlKey);
        this.saveMostViewedVideos(urlKey)
        this.props.getSelectedVideos({ id: urlKey });
    }
    render() {
        return (
            <div>
                <NewVideo
                    selectedVideo={this.props.selectedVideo}
                    share={true}
                />
            </div>
        )
    }
    saveMostViewedVideos = (urlId) => {
        let obj = {
            videoId: parseInt(urlId),
        }
        VideoService.saveMostViewedVideos(obj).then((response) => {

        })
            .catch(err => {

            })
    }
}
const mapStateToProps = function (state) {
    return {
        selectedVideo: state.video.selectedVideo,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getSelectedVideos: (a) => dispatch(getSelectedVideos(a))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ShareConatiner);

import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import TitleWithButton from "../../components/TitleWithButton";
import { getAllUserDetails } from "../../redux/actions/ManageUserActions";
import AuthService from "../../session/AuthService";
import NewUserDialog from "./NewUserDialog.jsx";
import NewUserGrid from "./NewUserGrid";

var page = 0;
class UserContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newUser: false,
      userlist: [],
      search: "",
    };
    this.loadStaffList = this.loadStaffList.bind(this);
    this.loadStaffList2 = this.loadStaffList2.bind(this);
    this.search = this.search.bind(this);
  }

  NewUser = () => {

    this.setState({
      newUser: !this.state.newUser,
    });
  };

  componentDidMount() {
    this.props.getAllUserDetails({ page: 0, search: "" });
  }

  render() {
    const logout = () => {

      AuthService.removeAuth();
      this.props.history.push(`/`);
    }
    return (
      <div className="manageTeamContainer screenInnerGap" style={{padding: "0 2%"}}>
        <TitleWithButton
          title={"Manage User"}
          onClick={this.NewUser}
          buttonLabel={"Create New User"}
          label={"logout"}
          buttonClick={logout}
        />
        <Container fluid>
          <Row>
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ paddingLeft: 0 }}
              className="column"
            >

              <>
                <NewUserGrid
                  search={(a) => this.search(a)}
                  userData={this.props.userData}
                  loadStaffEdit={() => this.loadStaffList()}
                  loadStaff2={() => this.loadStaffList()}
                />
                <br /><br />
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  prevPageText='PREV'
                  nextPageText='NEXT'
                  hideFirstLastPages
                  pageRangeDisplayed={10}
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.props.userCount}
                  onChange={(i) => this.handlePageChange(i)}
                />
              </>
            </Col>
          </Row>
        </Container>
        {this.state.newUser && (
          <NewUserDialog
            option="new"
            onHide={() => this.NewUser()}
            loadStaff={() => this.loadStaffList()}
          />
        )}
      </div>
    );
  }

  search(a) {

    this.setState({
      activePage: 1,
      search: a,
    });
    this.props.getAllUserDetails({ page: 0, search: a });
  }
  handlePageChange(i) {
    page = i - 1;
    var a = this.state.activePage;
    a = i - 1;
    this.setState({
      activePage: i,
    });


    this.props.getAllUserDetails({ page: a, search: this.state.search });
  }

  loadStaffList(pageno) {
    this.props.getAllUserDetails({ page: 0, search: "" });
  }
  loadStaffList2() {
    this.props.getAllUserDetails({ page: 0, search: "" });
  }
}

const mapStateToProps = function (state) {

  return {
    userCount: state.manageUser.userCount,
    userData: state.manageUser.userlist
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getAllUserDetails: (a) => dispatch(getAllUserDetails(a)),
  };
};

export default connect(mapStateToProps, mapDispacthToProps)(withRouter(UserContainer));

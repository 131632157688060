import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@material-ui/core/CircularProgress";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React, { Component } from "react";
import { Form, Modal, Table } from "react-bootstrap";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import ManageUserService from "../../api/ManageUserService";
import { getAllUserDetails } from "../../redux/actions/ManageUserActions";
import NewUserDialog from "./NewUserDialog.jsx";

var Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 1000,
  activePage: 1,
});
class NewUserGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuState: false,
      userDeleteDialogue: false,
      openEditUserDetails: false,
      obj: {},
      userId: "",
      search: "",
    };
  }
  onSearch = (e) => {
    this.setState({
      search: e.target.value,
    });
    this.props.search(e.target.value);
  }
  render() {

    return (
      <div className="manageTeamGridWrapper">
        <div className=" title-color title-content d-flex">
          <div className="titleMd uppercase">Users</div>
          <div className="search-box">
            <input
              type="text"
              name="search"
              value={this.state.search}
              onChange={this.onSearch}
              placeholder="search..."
              className="searchInput"
              autoComplete="off"
            />
          </div>
        </div>
        <Table className="border-set text-center" responsive>
          <thead className="gridHeader">
            <tr>
              <td style={{ minWidth: 200 }}>
                <p className="labelMd bold">Name</p>
              </td>
              <td style={{ minWidth: 200 }}>
                <p className="labelMd bold">Email</p>
              </td>
              <td style={{ minWidth: 150 }}>
                <p className="labelMd bold">Phone Number</p>
              </td>
              <td style={{ minWidth: 100 }}>
                <p className="labelMd bold">Action</p>
              </td>
            </tr>
          </thead>
          <tbody>
            {this.props.userData &&
              this.props.userData.length > 0 &&
              this.props.userData.map((data, i) => (
                <>
                  <tr>
                    <td>
                      <p className="labelLg">
                        {data.name ? data.name : "-"}
                      </p>
                    </td>
                    <td>
                      <p className="labelLg">{data.mailID ? data.mailID : "-"}</p>
                    </td>
                    <td>
                      <p className="labelLg">
                        {data.phone ? data.phone : "-"}
                      </p>
                    </td>
                    <td className="MTActionIcon">
                      <FontAwesomeIcon
                        icon={faEllipsisH}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.caseUpdate(e, data.id)}
                      />
                      <div className="editCase">
                        <Menu
                          id="simple-menu"
                          anchorEl={this.state.menuState}
                          keepMounted
                          open={this.state.menuState}
                          onClose={() => this.handleMenuClose()}
                        >
                          <div
                            className="menuCard"
                            style={{ display: "inline-flex" }}
                            onClick={() => this.editStaffDetails()}
                          >
                            <MenuItem>Edit</MenuItem>
                          </div>
                          <br />
                          <div
                            className="menuCard"
                            style={{ display: "inline-flex" }}
                            onClick={() => this.deleteStaff(data.id)}
                          >
                            <MenuItem>Delete</MenuItem>
                          </div>
                        </Menu>
                      </div>
                    </td>
                  </tr>
                </>
              ))}
            {this.props.userData && this.props.userData.length === 0 && (
              <p className="mt-3">{"No records found"}</p>
            )}
          </tbody>
        </Table>
        {this.state.openEditUserDetails && (
          <NewUserDialog
            loadStaffEdit={() => this.loadStaffList()}
            option="edit"
            onHide={() => this.onHide()}
            obj={this.state.obj}
          />
        )}
        {this.props.loading &&
          <CircularProgress
            style={{ left: "47%", position: "absolute", top: "45%" }}
            size={50}
          />}
        {this.state.userDeleteDialogue && this.deleteStaffPopUp()}
      </div>
    );
  }
  caseUpdate = (event, id) => {
    this.setState({ menuState: event.target, staffIds: id, staffId: id });
  }
  handleMenuClose = () => {
    this.setState({
      menuState: false,
    });
  }

  loadStaffList = async () => {
    await this.props.loadStaff2(0);
    this.setState({ openeditStaffDialogue: false });
  }

  deleteStaffPopUp = () => {
    return (
      <Modal show={true} size="md" centered onHide={() => this.handleClose()}>
        <Modal.Header closeButton className="CNSHeader">
          <h3 className="titleMd uppercase">Delete User</h3>
        </Modal.Header>
        <Form
          noValidate
          validated={this.state.validated}
          onSubmit={this.deleteStaffDetails}
        >
          <Modal.Body className="">
            <div className="basicInfoWrapper newServiceFormWrapper">
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Are you sure to Delete this User ?</Form.Label>
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer className="CNSFooter">
            <button className="defaultBtn contained" type="submit">
              Delete
            </button>
            <button className="defaultBtn" onClick={() => this.handleClose()}>
              Cancel
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  deleteStaff = (Id) => {
    this.setState({
      userDeleteDialogue: true,
      menuState: false,
      userId: Id,
    });
  }

  handleClose = () => {
    this.setState({ userDeleteDialogue: false });
  }

  editStaffDetails = async () => {
    let options = this.props.userData.filter(l => (l.id === this.state.staffId));
    await this.setState({ openEditUserDetails: true, obj: options, menuState: false })
  }

  onHide = () => {
    this.setState({ openEditUserDetails: false, obj: {} }, () => {
      this.props.getAllUserDetails({ page: 0, search: "" });
    })
  }

  deleteStaffDetails = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      event.preventDefault();
      event.stopPropagation();
      var user = {
        id: this.state.staffId,
      };
      ManageUserService.deleteUser(user)
        .then((response) => {


          // Toast.fire({
          //   icon: "success",
          //   title: "User Deleted Successfully",
          // });
          this.props.loadStaffEdit(0);
          this.setState({
            userDeleteDialogue: false,
          });
        })
        .catch((error) => {

        });
    }
    this.setState({ menuState: false });
  }
}
const mapStateToProps = function (state) {

  return {
    userData: state.manageUser.userlist,
    userCount: state.manageUser.userCount,
    loading: state.manageUser.loading
  };
};
const mapDispacthToProps = (dispatch) => {
  return {
    getAllUserDetails: (a) => dispatch(getAllUserDetails(a)),
  };
};

export default connect(mapStateToProps, mapDispacthToProps)(NewUserGrid);

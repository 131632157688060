import {
  GET_ALL_MOST_POPULAR_VIDEOS,
  GET_ALL_SHARED_VIDEOS,
  GET_ALL_VIDEOS_DETAILS,
  GET_ALL_VIEW_VIDEOS,
  GET_SELECTED_VIDEOS,
} from "../types";

const initialState = {
  videoDetails: [],
  sharedDetails: [],
  selectedVideo: [],
  viewedDetails: [],
  popularDetails: [],
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_VIDEOS_DETAILS:
      return {
        ...state,
        videoDetails: action.payload.data,
        loading: false,
      };
    case GET_ALL_SHARED_VIDEOS:
      return {
        ...state,
        sharedDetails: action.payload,
        loading: false,
      };
    case GET_ALL_VIEW_VIDEOS:
      return {
        ...state,
        viewedDetails: action.payload.data,
        loading: false,
      };
    case GET_ALL_MOST_POPULAR_VIDEOS:
      return {
        ...state,
        popularDetails: action.payload.data,
        loading: false,
      };
    case GET_SELECTED_VIDEOS:
      return {
        ...state,
        selectedVideo: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
}

import React, { Component } from "react";
import Swal from "sweetalert2";
import VideoService from "../../api/VideoService";
import AuthService from "../../session/AuthService";

const SwalWithUnbounce = Swal.mixin({
  showClass: {
    popup: "animate__animated animate__zoomIn", // Add your preferred unbounce animation class here
  },
  hideClass: {
    popup: "animate__animated animate__zoomOut", // Add your preferred exit animation class here
  },
});
class ViewEditVideoGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      enableEdit: false,
      editIndex: null,
      showTitleCard : false 
    };
    this.textRefs = {}
  }
  componentDidMount() {
    this.props.loadViewedVideo();
  }
  render() {
    const { showVideo, updateDetails } = this.props;
    return (
      <>
        {this.props.viewedVideo &&
          this.props.viewedVideo.length > 0 &&
          this.props.viewedVideo.map((element, idx) => (
            <div className="card-image">
              <img
                className="image-card"
                onClick={() => showVideo(element)}
                frameborder="0"
                width="340"
                loading="lazy"
                height=""
                src={`http://img.youtube.com/vi/${element.link}/mqdefault.jpg`}
              />
              <>
                <div className="d-flex align-items-center apple">
                  <div
                    ref={(ref) => (this.textRefs[idx] = ref)}
                    style={{
                      outline:
                        this.state.enableEdit === true &&
                        this.state.editIndex === idx
                          ? "1px solid black"
                          : "none",
                      fontWeight: "bold",
                      fontSize: "large",
                      minHeight: "0px",
                      textOverflow:
                        this.state.showTitleCard &&
                        this.state.editIndex === idx
                          ? "clip"
                          : "ellipsis",
                      textAlign:
                        this.state.showTitleCard &&
                        this.state.editIndex === idx
                          ? "start"
                          : "inherit",
                    }}
                    id={element.id}
                    onFocus={() => this.setState({showTitleCard : true , editIndex: idx,  enableEdit: true})}
                    onBlur={() => {
                      this.textRefs[idx].scrollLeft = 0;
                      this.setState(
                        { enableEdit: false, editIndex: null, showTitleCard : false },
                        () => {
                          this.props.checkAccess(element) && updateDetails(element, element.name);
                        }
                      );
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        this.props.checkAccess(element) && updateDetails(element);
                      }
                    }}
                    className={`video-title ${idx} ${this.state.enableEdit} ${this.state.editIndex}`}
                    contenteditable="true"
                  >
                    {element.name}
                  </div>
                  {this.props.checkAccess(element) && (
                    <span
                      title="Delete video"
                      className="pointer"
                      onClick={() => this.removeImage(element.id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20px"
                        height="20px"
                        fill="currentColor"
                        class="bi bi-trash3-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                      </svg>
                    </span>
                  )}
                </div>
                <div className="nowrap video-details">
                  {`${element.created_date.split(" ")[0]}`}&nbsp;&nbsp;{`-`}
                  &nbsp;&nbsp;
                  {element &&
                    element.totalDuration !== "" &&
                    element.totalDuration !== null &&
                    element.totalDuration}
                  &nbsp;&nbsp;{`-`}&nbsp;&nbsp;
                  {element.events.length + " events"}
                </div>
              </>
            </div>
          ))}
        {this.props.viewedVideo.length === 0 && (
          <>
            <div className="ps-4 pt-4">{"None Yet"}</div>
          </>
        )}
      </>
    );
  }

  removeImage = (id) => {
    SwalWithUnbounce.fire({
      html: "<div class='delete-content'>Deleting this annotation document will delete ALL of the events and tags for this document, not just the events and tags you have created.<div>",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
      position: "top-center",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (id !== "" && id !== 0) {
          let obj = {};
          obj["id"] = id;
          obj["user_id"] = parseInt(AuthService.getUserId());

          VideoService.deleteVideo(obj).then((response) => {
            if (response.data.status === 1) {
              this.props.loadSharedData();
              this.props.loadViewedVideo();
              SwalWithUnbounce.fire(
                "Deleted!",
                "Your video has been deleted.",
                "success"
              );
            }
          });
        }
      }
    });
  };
}
export default ViewEditVideoGrid;

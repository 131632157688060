import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import "./index.css";
import store from './redux/store';
import Navigations from "./routers/Navigations";

ReactDOM.render(
  <Provider store={store}>
    <Navigations />
  </Provider>,
  document.getElementById("root")
);

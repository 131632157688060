import VideoService from "../../api/VideoService";
import {
  GET_ALL_MOST_POPULAR_VIDEOS,
  GET_ALL_SHARED_VIDEOS,
  GET_ALL_VIDEOS_DETAILS,
  GET_ALL_VIEW_VIDEOS,
  GET_SELECTED_VIDEOS,
} from "../types";

export const getAllVideoDetails = (a) => async (dispatch) => {
  try {
    var obj = {
      userId: parseInt(a.userId),
    };
    VideoService.getAllVideoDetails(obj)
      .then((response) => {
        if (response.data && response.data.data) {
          dispatch({
            type: GET_ALL_VIDEOS_DETAILS,
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {});
  } catch (error) {
    dispatch({
      type: GET_ALL_VIDEOS_DETAILS,
      payload: [],
    });
  }
  return [];
};
export const getAllSharedVideos = (a) => async (dispatch) => {
  try {
    var obj = {
      pageNo: a.pageNo,
      userId: a.userId,
    };
    VideoService.getAllSharedVideos(obj)
      .then((response) => {
        if (response.data && response.data.data) {
          dispatch({
            type: GET_ALL_SHARED_VIDEOS,
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {});
  } catch (error) {
    dispatch({
      type: GET_ALL_SHARED_VIDEOS,
      payload: [],
    });
  }
  return [];
};
export const emptyReduxData = (a) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SELECTED_VIDEOS,
      payload: [],
    });
  } catch (error) {
    dispatch({
      type: GET_SELECTED_VIDEOS,
      payload: [],
    });
  }
  return [];
};
export const getAllViewedVideos = (a) => async (dispatch) => {
  try {
    var obj = {
      pageNo: a.pageNo,
      search: a.search,
    };
    VideoService.getAllViewedVideos(obj)
      .then((response) => {
        if (response.data && response.data.data) {
          dispatch({
            type: GET_ALL_VIEW_VIDEOS,
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {});
  } catch (error) {
    dispatch({
      type: GET_ALL_VIEW_VIDEOS,
      payload: [],
    });
  }
  return [];
};
export const getAllPopularVideos = (a) => async (dispatch) => {
  try {
    var obj = {
      pageNo: a.pageNo,
      search: a.search,
    };
    VideoService.getAllPopularVideos(obj)
      .then((response) => {
        if (response.data && response.data.data) {
          dispatch({
            type: GET_ALL_MOST_POPULAR_VIDEOS,
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {});
  } catch (error) {
    dispatch({
      type: GET_ALL_MOST_POPULAR_VIDEOS,
      payload: [],
    });
  }
  return [];
};
export const getSelectedVideos = (a) => async (dispatch) => {
  try {
    var obj = {
      id: a.id,
    };

    VideoService.getSelectedVideo(obj)
      .then((response) => {
        if (response.data && response.data.data) {
          dispatch({
            type: GET_SELECTED_VIDEOS,
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {});
  } catch (error) {
    dispatch({
      type: GET_SELECTED_VIDEOS,
      payload: [],
    });
  }
  return [];
};

import axios from 'axios'
import * as APPCONFIG from '../config/app-config.js'
import Http from './Http.js';
import AuthService from '../session/AuthService.js';

var axiosApi = axios.create({
    headers: Http.AUTH_HEADERS(),
    baseURL: APPCONFIG.API_URL
});

axiosApi.interceptors.response.use(function (response) {
    // Do something with response data
    //alert("Response");
    return response;
}, function (error) {
    if (error && error.response) {
        if (error.response.status) {
            switch (error.response.status) {
                case 401:
                    //alert("Error >>");
                    AuthService.removeAuth()
                    //window.location.reload();
                    break
                case 503:
                   // alert("Error");
                    //props.history.push('/503') //we will redirect user into 503 page 
                    break
                default:
                    break
            }
        }
    }
    // Do something with response error
    return Promise.reject(error);
});

export default axiosApi;
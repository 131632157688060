import React, { Component } from "react";

export default class TitleWithButton extends Component {
  render() {
    const { title, onClick, buttonLabel, buttonhide, label, buttonClick } = this.props;
    return (
      <div className="title-content d-flex">
        <h3 className="titleLg uppercase bold">{title}</h3>
        {!buttonhide && (
          <div>
            {label &&
              <button data-tip data-for="registerTip" onClick={buttonClick} className="defaultBtn uppercase contained">
                {label}
              </button>
            }&nbsp;
            <button className="defaultBtn uppercase contained" onClick={onClick}>
              {buttonLabel}
            </button>
          </div>
        )}
      </div>
    );
  }
}

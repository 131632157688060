import React, { Component, memo } from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { Form, Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import ReactDOM from "react-dom";
import ReactPlayer from "react-player";
import "react-select2-wrapper/css/select2.css";
import "react-widgets/styles.css";
import Swal from "sweetalert2";
import VideoService from "../../api/VideoService";
import AuthService from "../../session/AuthService";
import addImage from "../../vImages/add.svg";
import { default as remove } from "../../vImages/delete.svg";
import fastForward from "../../vImages/fastforward-frame.svg";
import forwardIncrement from "../../vImages/fastforward-increment.svg";
import rewindFrame from "../../vImages/rewind-frame.svg";
import revindIncrement from "../../vImages/rewind-increment.svg";
import Timeline from "../Timeline/Timeline";

var Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 1000,
});

var ToastError = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 1000,
});

var fromOld = 0;
var toOld = 0;
var array = [];
var increment = 0;
var selected_array = [];
var userData = [];
var watchEventListener = "NOEVENT";
var oneTimeRunner = "NONE";

function IsExists(mj) {
  if (mj === null || mj === undefined || mj === "") {
    return false;
  }
  return true;
}

function sortByTime(data) {
  if (data) {
    return data.sort((a, b) => a.start_time - b.start_time);
  }
}
function sortByAlpha(data) {
  if (data) {
    return data.sort((a, b) => a.name.localeCompare(b.name));
  }
}

class NewVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { eventColor: "#000000" },
      usersObject: {},
      extraUsersObject: {},
      youtubeInfoObject: {},
      settingFormValues: {},
      shareObject: {},
      colorForm: { inherit: true, use: false },
      blocking: false,
      access: false,
      validated: false,
      eventsPlay: false,
      setting: false,
      videoInfo: false,
      events: true,
      share: false,
      lock: false,
      textShow: false,
      openPopup: false,
      tags: false,
      time: true,
      alpha: false,
      playAll: false,
      enableTags: true,
      enableEvents: true,
      enableAccess: true,
      enableSettings: true,
      editForm: false,
      playerVideo: false,
      ViewEditEntry: false,
      userSearch: "",
      shareUser: "",
      playingIndex: "",
      videoPosition: null,
      nextEventOnEdit: null,
      shareValue: "",
      extraUser: [],
      userDetails: [],
      playerValues: [],
      shareDetails: [],
      playing: false,
      selectedObjectData: [],
      allEvents: [],
      allObjects: [],
      playingCommon: [],
      accessDetails: [],
      accessExtraDetails: [],
      extraCommonValue: [],
      videoObject: {},
      varPlayerValues: [],
      timelineDetails: [],
      timelineArray: [],
      timelineTagId: [],
      videoAllEvents: [],
      position: 0,
      timeline_id: 0,
      totalDuration: 0,
      currentId: 0,
      onPlayStart: 0,
      onPlayEnd: 0,
      eventLength: 0,
      timelineLength: 1,
      enableAnnotationNameEdit: false,
      playedSeconds: 0,
      showTitleCard: false,
    };
    this.timer = null;
    this.timeoutId = null;
    this.player = null;
    this.textRefs = null;
  }

  componentDidMount() {
    if (IsExists(this.props.selectedVideo)) {
      this.setAllData(this.props);
    }
    this.showDarkColorAndPlayIcon(this.state.position);
  }

  updateVideoDetails = () => {
    let btn = document.getElementById(`${this.state.videoObject.id}-videoName`);

    if (
      btn.textContent !== "" &&
      btn.textContent !== null &&
      btn.textContent !== undefined
    ) {
      let obj = {
        name: btn.textContent.trim(),
        id: this.state.videoObject.id,
      };
      this.setState(
        {
          blocking: true,
        },
        () => {
          VideoService.updateVideoDetails(obj).then((response) => {
            if (response.data.status === 1) {
              this.setState({ blocking: false }, () => {
                this.props.loadBack(this.state.videoObject.id);
              });
            }
          });
        }
      );
    } else {
      btn.innerHTML = this.state.videoObject.name;
    }
  };
  unBindSelectedEvent = () => {
    this.setState({
      editForm: false,
      formData: {},
      colorForm: { inherit: true, use: false },
    });
  };
  componentWillReceiveProps(nextProps) {
    if (IsExists(nextProps.selectedVideo)) {
      this.setAllData(nextProps);
    }
  }
  componentWillUnmount() {
    this.timer = null;
    clearTimeout(this.timeoutId);
    this.stopVideo();
  }

  stopVideo = () => {
    if (this.player) {
      this.player = null;
    }
  };

  render() {
    return (
      <>
        <div className={`${!this.props.share ? "content-page" : "mt-3"}`}>
          <BlockUi
            blocking={this.state.blocking}
            message={"Loading, please wait"}
            keepInView
            color="#60c2e7"
          >
            <div className="bottom-wrapper p-2 apple">
              <div
                ref={(ref) => (this.textRefs = ref)}
                style={{
                  width: `${!this.props.share ? "49%" : "100%"}`,
                  outline: this.state.enableAnnotationNameEdit === true
                  ? "black solid 1px"
                  : "none",
                  minHeight: "0px",
                  textOverflow: this.state.showTitleCard ? "clip" : "ellipsis",
                }}
                onFocus={() =>
                  this.setState({
                    showTitleCard: true,
                    enableAnnotationNameEdit: true
                  })
                }
                onBlur={() => {
                  this.textRefs.scrollLeft = 0;
                  this.setState(
                    { enableAnnotationNameEdit: false, showTitleCard: false },
                    () => {
                      this.state.enableAccess && this.updateVideoDetails();
                    }
                  );
                }}
                contenteditable="true"
                id={`${this.state.videoObject.id}-videoName`}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    this.state.enableAccess && this.updateVideoDetails();
                  }
                }}
                className="fw-bold text-truncate fs-5 mb-1"
              >
                {this.state.videoObject && this.state.videoObject.name}
              </div>
              <div className="row">
                <div
                  className={`${
                    !this.props.share && !this.state.lock
                      ? "col-sm-6 mb-3"
                      : "col-sm-12 mb-3"
                  }`}
                >
                  <div
                    className={`${
                      !this.props.share
                        ? "youtube-embed-responsive youtube-embed-responsive-16by9 mb-3"
                        : "youtube-embed-responsive youtube-embed-responsive-height mb-3"
                    }`}
                  >
                    <ReactPlayer
                      ref={(ref) => {
                        this.player = ref;
                      }}
                      progressInterval={10}
                      className="react-player youtube-embed-responsive-item mb-3 user-select-none"
                      width="100%"
                      height="100%"
                      controls={false}
                      preload="auto"
                      config={{
                        youtube: {
                          playerVars: {
                            modestbranding: 1,
                            fs: 0,
                            rel: 0,
                            showinfo: 0,
                            controls: 0,
                            disablekb: 1,
                            iv_load_policy: 3,
                          },
                        },
                      }}
                      playing={this.state.playing}
                      onReady={() => {
                        watchEventListener = "onReady";
                        oneTimeRunner = "onReady";
                        this.onPlayerReady();
                        console.log(watchEventListener);
                      }}
                      onDuration={(time) => {
                        watchEventListener = "onDuration";
                        this.handleDuration(time);
                        console.log(watchEventListener);
                      }}
                      onBuffer={() => {
                        watchEventListener = "onBuffer";
                        if (
                          !this.state.playing &&
                          oneTimeRunner === "onReady"
                        ) {
                          this.play();
                          oneTimeRunner = "NONE";
                        }
                        console.log(watchEventListener, oneTimeRunner);
                      }}
                      onSeek={() => (watchEventListener = "onSeek")}
                      onError={(error) => {
                        if (
                          ReactPlayer.canPlay(
                            `https://www.youtube.com/watch?rel=0&v=${this.state.videoObject.link}`
                          )
                        ) {
                          ToastError.fire({
                            icon: "error",
                            title:
                              "This Video Blocked By Youtube Due to Privacy",
                          });
                        }

                        watchEventListener = "onError";
                        console.log(watchEventListener);
                      }}
                      onPause={() => {
                        this.pause();
                        watchEventListener = "onPause";
                        console.log(watchEventListener);
                      }}
                      onStart={() => {
                        this.seekTo(fromOld);
                        watchEventListener = "onStart";
                        console.log(watchEventListener);
                      }}
                      onPlay={() => {
                        this.play();
                        this.clickIconChange(this.state.position);
                        this.setState({
                          events: true,
                          tags: false,
                          setting: false,
                          access: false,
                          share: false,
                        });
                        watchEventListener = "onPlay";
                        oneTimeRunner = "NONE";
                        console.log(watchEventListener);
                      }}
                      onProgress={this.progress}
                      url={
                        this.player !== undefined &&
                        `https://www.youtube.com/watch?v=${this.state.videoObject.link}`
                      }
                    />
                  </div>
                  {!this.props.share && (
                    <>
                      <img
                        loading="lazy"
                        class="icon icon-standard-size float-right pointer"
                        onClick={() => this.saveEvents()}
                        src={addImage}
                        title="Create Events"
                      />
                    </>
                  )}
                </div>
                {!this.props.share && !this.state.lock && (
                  <>
                    <div className="col-sm-6">
                      <ul class="nav nav-tabs" id="selecttab">
                        <li
                          className={`${
                            this.state.events
                              ? "btn-active fw-bold fs-5 nav-item"
                              : "btn-inactive nav-item"
                          }`}
                          id="events_tb"
                          onClick={(e) => this.userNavigation(e)}
                        >
                          <a
                            class="nav-link ps-0"
                            id="events_tab"
                            data-toggle="tab"
                          >
                            Events
                          </a>
                        </li>
                        <li
                          className={`${
                            this.state.tags
                              ? "btn-active fw-bold fs-5 nav-item"
                              : "btn-inactive nav-item"
                          }`}
                          id="tags_tb"
                          onClick={(e) => this.userNavigation(e)}
                        >
                          <a
                            className="nav-link"
                            id="tags_tab"
                            data-toggle="tab"
                          >
                            Tags
                          </a>
                        </li>
                        <li
                          id="access"
                          className={`${
                            this.state.access
                              ? "btn-active fw-bold fs-5 nav-item"
                              : "btn-inactive nav-item"
                          }`}
                          onClick={(e) => this.userNavigation(e)}
                        >
                          <a className="nav-link" id="access" data-toggle="tab">
                            Access
                          </a>
                        </li>
                        <li
                          className={`${
                            this.state.setting
                              ? "btn-active fw-bold fs-5 nav-item"
                              : "btn-inactive nav-item"
                          }`}
                          id="settings"
                          onClick={(e) => this.userNavigation(e)}
                        >
                          <a
                            className="nav-link"
                            id="settings"
                            data-toggle="tab"
                          >
                            Settings
                          </a>
                        </li>
                        <li
                          className={`${
                            this.state.share
                              ? "btn-active fw-bold fs-5 nav-item"
                              : "btn-inactive nav-item"
                          }`}
                          id="share"
                          onClick={(e) => this.userNavigation(e)}
                        >
                          <a className="nav-link" id="share" data-toggle="tab">
                            Share
                          </a>
                        </li>
                      </ul>
                      <hr className="hr-line" />
                      {this.state.events && (
                        <>
                          <div className="table w-100">
                            <div className="table-row">
                              <div className="table-cell bottom-align edit-pane-table-cell-top-padding label-above">
                                <label className="ps-0 label">events</label>

                                <label className="float-right">
                                  {this.state.allEvents &&
                                    this.state.allEvents.length > 0 && (
                                      <>
                                        <span
                                          onClick={() =>
                                            this.eventsOrderChange("time")
                                          }
                                          className={`${
                                            this.state.time
                                              ? "black cursor pointer"
                                              : "blue cursor pointer"
                                          }`}
                                        >
                                          time
                                        </span>{" "}
                                        |{" "}
                                        <span
                                          onClick={() =>
                                            this.eventsOrderChange("alpha")
                                          }
                                          className={`${
                                            this.state.alpha
                                              ? "black cursor pointer"
                                              : "blue cursor pointer"
                                          }`}
                                        >
                                          alpha
                                        </span>
                                      </>
                                    )}
                                </label>
                              </div>

                              <div className="label table-cell bottom-align edit-pane-table-cell-top-padding edit-pane-link-table-left-padding label-above">
                                {this.state.editForm &&
                                  this.state.allEvents &&
                                  this.state.allEvents.length > 0 && (
                                    <div className="label table-cell ps-0">
                                      name
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="table-row">
                              <div className="top-align table-cell minimal-width">
                                <div
                                  id="events"
                                  className="select edit-pane-link-list"
                                >
                                  {this.state.allEvents &&
                                    this.state.allEvents.map((element) => (
                                      <ul
                                        onClick={(e) => this.handleEvents(e)}
                                        className={`list-style-none eventUl_${element.id}`}
                                        id={`eventBuildUl_${element.id}`}
                                      >
                                        <li
                                          className={`eventListItem eventLst_${
                                            element.id
                                          } ${
                                            this.state.editForm &&
                                            element.id ===
                                              this.state.formData.id
                                              ? "activeBlueObject"
                                              : ""
                                          }`}
                                          style={{
                                            width: "18rem",
                                            backgroundColor:
                                              this.state.editForm &&
                                              element.id ===
                                                this.state.formData.id
                                                ? "#3399FF"
                                                : "white",
                                          }}
                                          id={element.id}
                                          value={element.id}
                                        >
                                          {element.name}
                                        </li>
                                      </ul>
                                    ))}
                                </div>

                                {this.state.editForm &&
                                  this.state.allEvents &&
                                  this.state.allEvents.length > 0 && (
                                    <a className="p-0">
                                      <img
                                        loading="lazy"
                                        className="icon icon-standard-size pointer"
                                        onClick={() => {
                                          this.removeAll(this.state.formData);
                                        }}
                                        src={remove}
                                        title="Delete selected event"
                                      />
                                    </a>
                                  )}
                              </div>
                              {this.state.editForm &&
                                this.state.allEvents &&
                                this.state.allEvents.length > 0 && (
                                  <div className="top-align table-cell">
                                    <div className="d-table w-100 edit-pane-link-table-left-padding padding">
                                      <div className="table-row">
                                        <div className="edit-pane-table-cell table-cell">
                                          <input
                                            className="standard-textbox w-100 mb-2"
                                            onChange={(e) => this.handleForm(e)}
                                            value={this.state.formData.name}
                                            name="name"
                                            id="name"
                                            type="text"
                                            required=""
                                          />
                                        </div>
                                      </div>
                                      <div className="table-row">
                                        <div className="label table-cell label-above">
                                          description
                                        </div>
                                      </div>
                                      <div className="table-row">
                                        <div className="edit-pane-table-cell table-cell">
                                          <textarea
                                            className="standard-textarea w-100"
                                            onChange={(e) => this.handleForm(e)}
                                            value={
                                              this.state.formData.description ||
                                              ""
                                            }
                                            name="description"
                                            id="description"
                                            rows="3"
                                            cols="50"
                                          />
                                        </div>
                                      </div>
                                      <div className="table-row">
                                        <div className="label table-cell label-above">
                                          tags
                                        </div>
                                      </div>
                                      <div className="table-row">
                                        <div className="edit-pane-table-cell table-cell">
                                          <div>
                                            <div>
                                              <div className="d-flex flex-wrap gap-2 mb-4">
                                                {this.state.allObjects &&
                                                  this.state.allObjects.map(
                                                    (data, index) => (
                                                      <div
                                                        onClick={(e) =>
                                                          this.tagsChange(
                                                            data,
                                                            e
                                                          )
                                                        }
                                                        id={data.name}
                                                        title={data.name}
                                                        style={{
                                                          whiteSpace: "nowrap",
                                                          overflow: "hidden",
                                                          textOverflow:
                                                            "ellipsis",
                                                          maxWidth: "245px",
                                                        }}
                                                        className={`${
                                                          this.highLightTags(
                                                            data.id
                                                          )
                                                            ? "grid-item grid-active"
                                                            : "grid-item grid-inactive"
                                                        }`}
                                                      >
                                                        {data.name}
                                                      </div>
                                                    )
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="table-row">
                                        <div className="table-cell float-left">
                                          <div className="table w-100">
                                            <div className="table-row">
                                              <div className="label table-cell position-duration-marker-label-padding">
                                                position & duration
                                              </div>
                                              <div className="label table-cell position-duration-marker-label-padding edit-pane-link-marker-color-padding">
                                                event color
                                              </div>
                                            </div>
                                            <div className="table-row">
                                              <div className="table-cell">
                                                <div className="table p-0 w-100">
                                                  <div className="table-row">
                                                    <div className="table-cell" />
                                                    <div className="table-cell">
                                                      <div
                                                        className="float-left p-0 middle-align"
                                                        style={{
                                                          fontSize: "13px",
                                                        }}
                                                      >
                                                        {this.convertToHHMMssSSS(
                                                          this.state.onPlayStart
                                                        )}
                                                      </div>
                                                      <div
                                                        className="float-right p-0 middle-align"
                                                        style={{
                                                          fontSize: "13px",
                                                        }}
                                                      >
                                                        {this.convertToHHMMssSSS(
                                                          this.state.onPlayEnd
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className="table-cell" />
                                                  </div>
                                                  <div className="table-row">
                                                    <div className="table-cell" />
                                                    <div
                                                      className="table-cell position-duration-edit-bar-margins"
                                                      colSpan={2}
                                                    >
                                                      <div
                                                        className="position-duration-edit-bar"
                                                        style={{
                                                          background: "#0F84BC",
                                                        }}
                                                      />
                                                    </div>
                                                    <div className="table-cell" />
                                                  </div>
                                                  <div className="table-row">
                                                    <div className="table-cell p-2 position-duration-button-cell">
                                                      <div className="float-right p-0">
                                                        <img
                                                          loading="lazy"
                                                          onClick={() =>
                                                            this.sliderSet(
                                                              "large_time_backward"
                                                            )
                                                          }
                                                          className="icon-standard-size pointer"
                                                          src={revindIncrement}
                                                          title="Rewind 1 second"
                                                        />
                                                        <img
                                                          loading="lazy"
                                                          onClick={() =>
                                                            this.sliderSet(
                                                              "small_time_backward"
                                                            )
                                                          }
                                                          className="icon-standard-size pointer"
                                                          src={rewindFrame}
                                                          title="Rewind 1 frame"
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="table-cell p-0 position-duration-button-cell">
                                                      <div className="float-left p-0">
                                                        <img
                                                          loading="lazy"
                                                          onClick={() =>
                                                            this.sliderSet(
                                                              "small_time_forward"
                                                            )
                                                          }
                                                          className="icon-standard-size pointer"
                                                          src={fastForward}
                                                          title="Fast forward 1 frame"
                                                        />
                                                        <img
                                                          loading="lazy"
                                                          onClick={() =>
                                                            this.sliderSet(
                                                              "large_time_forward"
                                                            )
                                                          }
                                                          className="icon-standard-size pointer"
                                                          src={forwardIncrement}
                                                          title="Fast forward 1 second"
                                                        />
                                                      </div>
                                                      <div className="float-right p-0">
                                                        <img
                                                          className="icon-standard-size pointer"
                                                          loading="lazy"
                                                          onClick={() =>
                                                            this.sliderSetEnd(
                                                              "large_time_backward"
                                                            )
                                                          }
                                                          src={revindIncrement}
                                                          title="Rewind 1 second"
                                                        />
                                                        <img
                                                          onClick={() =>
                                                            this.sliderSetEnd(
                                                              "small_time_backward"
                                                            )
                                                          }
                                                          className="icon-standard-size pointer"
                                                          loading="lazy"
                                                          src={rewindFrame}
                                                          title="Rewind 1 frame"
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="table-cell p-2 position-duration-button-cell">
                                                      <div className="float-left p-0">
                                                        <img
                                                          loading="lazy"
                                                          onClick={() =>
                                                            this.sliderSetEnd(
                                                              "small_time_forward"
                                                            )
                                                          }
                                                          className="icon-standard-size pointer"
                                                          title="Fast forward 1 frame"
                                                          src={fastForward}
                                                        />
                                                        <img
                                                          onClick={() =>
                                                            this.sliderSetEnd(
                                                              "large_time_forward"
                                                            )
                                                          }
                                                          className="icon-standard-size pointer"
                                                          loading="lazy"
                                                          title="Fast forward 1 second"
                                                          src={forwardIncrement}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="edit-pane-table-cell table-cell nowrap edit-pane-link-marker-color-padding">
                                                <div>
                                                  <input
                                                    type="radio"
                                                    name="response"
                                                    className="pointer standard-radio-button middle-align"
                                                    defaultValue="true"
                                                    checked={
                                                      this.state.colorForm
                                                        .inherit
                                                    }
                                                    onClick={(e) => {
                                                      if (
                                                        this.state.enableEvents
                                                      ) {
                                                        let form =
                                                          this.state.colorForm;
                                                        form["inherit"] = true;
                                                        form["use"] = false;
                                                        this.setState(
                                                          { colorForm: form },
                                                          () => {
                                                            let form =
                                                              this.state
                                                                .formData;
                                                            form["eventColor"] =
                                                              this.state.settingFormValues.event_default_color;
                                                            this.updateEvents();
                                                          }
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  inherit from video
                                                </div>
                                                <div className="nowrap pt-0">
                                                  <input
                                                    type="radio"
                                                    name="response"
                                                    className="pointer standard-radio-button middle-align inline-block"
                                                    defaultValue="false"
                                                    onClick={(e) => {
                                                      if (
                                                        this.state.enableEvents
                                                      ) {
                                                        let form =
                                                          this.state.colorForm;
                                                        form["use"] = true;
                                                        form["inherit"] = false;
                                                        this.setState(
                                                          { colorForm: form },
                                                          () => {
                                                            setTimeout(
                                                              function () {
                                                                document
                                                                  .getElementById(
                                                                    "colorPickerUi"
                                                                  )
                                                                  .click();
                                                              }
                                                            );
                                                          }
                                                        );
                                                      }
                                                    }}
                                                    checked={
                                                      this.state.colorForm.use
                                                    }
                                                  />
                                                  use
                                                  {this.state.colorForm.use && (
                                                    <input
                                                      type="color"
                                                      className="edit-pane-link-color-picker p-0 inline-block middle-align"
                                                      onChange={(e) =>
                                                        this.handleForm(e)
                                                      }
                                                      id="colorPickerUi"
                                                      value={
                                                        this.state.formData
                                                          .eventColor
                                                      }
                                                      autoComplete="off"
                                                      name="eventColor"
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </>
                      )}
                      {this.state.tags && (
                        <div className="table w-100">
                          <div className="table-cell timeline-controls-bottom-padding">
                            <div>
                              <form className="p-0" name="tagForm" novalidate>
                                <div class="edit-pane-table-cell table-cell edit-pane-table-cell-top-padding">
                                  <div>
                                    {this.state.allObjects &&
                                      this.state.allObjects.map(
                                        (data, index) => (
                                          <div className="tag-with-close tag-border tag-margin-right tag-margin-bottom">
                                            <div
                                              className="tag text-truncate"
                                              spellcheck="false"
                                              style={{
                                                maxWidth: "245px",
                                              }}
                                              title={data.name}
                                              onClick={() =>
                                                this.addButton(data)
                                              }
                                            >
                                              {data.name}
                                            </div>
                                            <div
                                              class="tag-close"
                                              title="remove object"
                                              onClick={() =>
                                                this.removeObject(index)
                                              }
                                            >
                                              x
                                            </div>
                                          </div>
                                        )
                                      )}
                                  </div>
                                  <div class="stacking-div">
                                    <a>
                                      <img
                                        loading="lazy"
                                        class="icon icon-standard-size"
                                        onClick={() => this.addButton()}
                                        src={addImage}
                                        title="Add new tag"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.access && (
                        <>
                          <div className="access-pane">
                            <table className="w-100 border-bottom-0">
                              <thead>
                                <tr className="vertically border-style-hidden">
                                  <th></th>

                                  <th className="label text-center">view</th>
                                  <th className="label text-center">
                                    Add/modify
                                    <br />
                                    events
                                  </th>
                                  <th className="label text-center">
                                    Add/modify
                                    <br />
                                    tags
                                  </th>
                                  <th className="label text-center">
                                    Add/modify
                                    <br />
                                    access
                                  </th>
                                  <th className="label text-center">
                                    Add/modify
                                    <br />
                                    settings
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="border-style-hidden">
                                  <td className="vertically text-center">
                                    public
                                  </td>
                                  <td>
                                    <div class="text-center">
                                      <input
                                        onChange={(e) => this.handleChange(e)}
                                        name="view"
                                        type="checkbox"
                                        checked="checked"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div class="text-center">
                                      <input
                                        onChange={(e) => this.handleChange(e)}
                                        name="event_access"
                                        type="checkbox"
                                        checked={
                                          this.state.usersObject.event_access
                                            ? this.state.usersObject
                                                .event_access
                                            : false
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div class="text-center">
                                      <input
                                        onChange={(e) => this.handleChange(e)}
                                        name="tag_access"
                                        type="checkbox"
                                        checked={
                                          this.state.usersObject.tag_access
                                            ? this.state.usersObject.tag_access
                                            : false
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div class="text-center">
                                      <input
                                        onChange={(e) => this.handleChange(e)}
                                        name="access"
                                        type="checkbox"
                                        checked={
                                          this.state.usersObject.access
                                            ? this.state.usersObject.access
                                            : false
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div class="text-center">
                                      <input
                                        onChange={(e) => this.handleChange(e)}
                                        name="setting_access"
                                        type="checkbox"
                                        checked={
                                          this.state.usersObject.setting_access
                                            ? this.state.usersObject
                                                .setting_access
                                            : false
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                                {this.state.extraUser &&
                                  this.state.extraUser.map((element, index) => (
                                    <tr>
                                      <td className="vertically  border-style-hidden pointer user_color d-flex">
                                        <img
                                          loading="lazy"
                                          className="icon-size"
                                          src={remove}
                                          onClick={(e) =>
                                            this.removeUser(index, element)
                                          }
                                        />
                                        &nbsp;&nbsp;
                                        <span className="userName-color">
                                          {element.name}
                                        </span>
                                      </td>
                                      <td>
                                        <div class="horizontally-centered middle-align">
                                          <input
                                            onChange={(e) =>
                                              this.extraHandleChange(e, index)
                                            }
                                            name="view"
                                            type="checkbox"
                                            checked={
                                              this.state.extraCommonValue &&
                                              this.state.extraCommonValue[index]
                                                ? this.state.extraCommonValue[
                                                    index
                                                  ].view
                                                : false
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div class="text-center">
                                          <input
                                            onChange={(e) =>
                                              this.extraHandleChange(e, index)
                                            }
                                            name="event_access"
                                            type="checkbox"
                                            checked={
                                              this.state.extraCommonValue &&
                                              this.state.extraCommonValue[index]
                                                ? this.state.extraCommonValue[
                                                    index
                                                  ].event_access
                                                : false
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div class="text-center">
                                          <input
                                            onChange={(e) =>
                                              this.extraHandleChange(e, index)
                                            }
                                            name="tag_access"
                                            type="checkbox"
                                            checked={
                                              this.state.extraCommonValue &&
                                              this.state.extraCommonValue[index]
                                                ? this.state.extraCommonValue[
                                                    index
                                                  ].tag_access
                                                : false
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div class="text-center">
                                          <input
                                            onChange={(e) =>
                                              this.extraHandleChange(e, index)
                                            }
                                            name="access"
                                            type="checkbox"
                                            checked={
                                              this.state.extraCommonValue &&
                                              this.state.extraCommonValue[index]
                                                ? this.state.extraCommonValue[
                                                    index
                                                  ].access
                                                : false
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div class="text-center">
                                          <input
                                            onChange={(e) =>
                                              this.extraHandleChange(e, index)
                                            }
                                            name="setting_access"
                                            type="checkbox"
                                            checked={
                                              this.state.extraCommonValue &&
                                              this.state.extraCommonValue[index]
                                                ? this.state.extraCommonValue[
                                                    index
                                                  ].setting_access
                                                : false
                                            }
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                            <button
                              onClick={() => {
                                if (this.state.enableAccess) {
                                  this.setState({
                                    openPopup: true,
                                    userSearch: [],
                                  });
                                }
                              }}
                              className="btn radius-content"
                            >
                              Add
                            </button>
                            <br />
                          </div>
                        </>
                      )}
                      {this.state.share && (
                        <>
                          <div className="mt-3">
                            <label className="col-md-6 me-3 mb-3  control-label">
                              Share this video:
                            </label>
                            <div className="row">
                              <div className="me-3 col-sm-7">
                                <div className="input-group mb-3 w-100">
                                  <input
                                    type="text"
                                    id="copyText"
                                    className="form-control"
                                    value={`${
                                      window.location.origin
                                    }/VideoTagging/share?search=${btoa(
                                      this.state.videoObject.id
                                    ).replace(/=/g, "")}&userId=public`}
                                    placeholder="link"
                                    aria-label="link"
                                  />
                                  <span
                                    className="input-group-text d-inline-block"
                                    id="basic-addon1"
                                  >
                                    <p
                                      id="copy"
                                      className="m-0"
                                      type="button"
                                      onClick={() => this.copyText()}
                                    >
                                      copy
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {this.state.setting && (
                        <div>
                          <br />
                          <tr>
                            <td>
                              <div className="row">
                                <div class="form-group has-success mb-2">
                                  <label className="col-md-4 control-input label">
                                    Event Name Prefix
                                  </label>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <div className="col mb-3 input">
                                    <input
                                      type="text"
                                      onChange={(e) => this.settingChange(e)}
                                      class="form-control"
                                      id="event_name_prefix"
                                      autoComplete="off"
                                      value={
                                        this.state.settingFormValues
                                          .event_name_prefix
                                      }
                                      name="event_name_prefix"
                                      placeholder="Event Name Prefix"
                                    />
                                  </div>
                                </div>
                              </div>
                              {false && (
                                <div className="row">
                                  <div class="form-group has-success mb-2">
                                    <label className="col-md-4 control-input label">
                                      Timeline Name Prefix
                                    </label>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <div className="col mb-3 input">
                                      <input
                                        type="text"
                                        onChange={(e) => this.settingChange(e)}
                                        class="form-control"
                                        id="timeline_name_prefix"
                                        autoComplete="off"
                                        value={
                                          this.state.settingFormValues
                                            .timeline_name_prefix
                                        }
                                        name="timeline_name_prefix"
                                        placeholder="Timeline Name Prefix"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="row">
                                <div className="form-group has-success mb-2">
                                  <label className="col-md-4 control-input label">
                                    Event Start Time Offset
                                    <br />
                                    <span>
                                      <label className="label">
                                        (When created)
                                      </label>
                                    </span>
                                  </label>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <div className="col input">
                                    <input
                                      type="text"
                                      class="form-control"
                                      onChange={(e) => this.settingChange(e)}
                                      id="event_start_time_offset"
                                      autoComplete="off"
                                      min="0"
                                      onKeyPress={(e) => {
                                        if (
                                          !/[0-9+]/.test(e.key) ||
                                          e.target.value.length >= 12
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onPasteCapture={(e) => {
                                        let clipboardData, pastedData;
                                        clipboardData =
                                          e.clipboardData ||
                                          window.clipboardData;
                                        pastedData =
                                          clipboardData.getData("Text");
                                        if (
                                          !/[0-9+]/.test(pastedData) ||
                                          pastedData.length >= 12
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      value={
                                        this.state.settingFormValues
                                          .event_start_time_offset
                                      }
                                      name="event_start_time_offset"
                                      placeholder="Enter Start Time Offset"
                                    />
                                  </div>
                                  <span>&nbsp;seconds</span>
                                </div>
                              </div>
                              <div className="row">
                                <div class="form-group has-success mb-2">
                                  <label class="col-md-4 control-input label">
                                    Event Default Length<span></span>
                                  </label>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <div className="col mb-3 input">
                                    <input
                                      type="text"
                                      class="form-control"
                                      onChange={(e) => this.settingChange(e)}
                                      id="event_default_length"
                                      autoComplete="off"
                                      min="0"
                                      onKeyPress={(e) => {
                                        if (
                                          !/[0-9+]/.test(e.key) ||
                                          e.target.value.length >= 12
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onPasteCapture={(e) => {
                                        let clipboardData, pastedData;
                                        clipboardData =
                                          e.clipboardData ||
                                          window.clipboardData;
                                        pastedData =
                                          clipboardData.getData("Text");
                                        if (
                                          !/[0-9+]/.test(pastedData) ||
                                          pastedData.length >= 12
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      value={
                                        this.state.settingFormValues
                                          .event_default_length
                                      }
                                      name="event_default_length"
                                      placeholder="Enter Default Length"
                                    />
                                  </div>
                                  <span>&nbsp;seconds</span>
                                </div>
                              </div>
                              <div className="row">
                                <div class="form-group has-success mb-2">
                                  <label class="col-md-4 control-input position label">
                                    Event Default Color<span></span>
                                  </label>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <div className="col d-inline-flex">
                                    <input
                                      type="color"
                                      className=" border-color edit-pane-link-color-picker p-0 ms-0 inline-block middle-align"
                                      onChange={(e) => this.settingChange(e)}
                                      id="event_default_color"
                                      value={
                                        this.state.settingFormValues
                                          .event_default_color
                                      }
                                      autoComplete="off"
                                      name="event_default_color"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div class="form-group has-success mb-2">
                                  <label class="col-md-4 control-input label">
                                    Small Time Increment
                                    <br />
                                    <span>
                                      (
                                      <img
                                        loading="lazy"
                                        style={{ maxWidth: "20px" }}
                                        src={rewindFrame}
                                      />
                                      &nbsp;
                                      <img
                                        style={{ maxWidth: "20px" }}
                                        src={fastForward}
                                      />
                                      <label className="label">buttons</label>)
                                    </span>
                                    <span></span>
                                  </label>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <div className="col input">
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="small_time_increment"
                                      autoComplete="off"
                                      min="0"
                                      onKeyPress={(e) => {
                                        if (
                                          !/[0-9.+]/.test(e.key) ||
                                          e.target.value.length >= 12
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onPasteCapture={(e) => {
                                        let clipboardData, pastedData;
                                        clipboardData =
                                          e.clipboardData ||
                                          window.clipboardData;
                                        pastedData =
                                          clipboardData.getData("Text");
                                        if (
                                          !/[0-9.+]/.test(pastedData) ||
                                          pastedData.length >= 12
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onChange={(e) => this.settingChange(e)}
                                      value={
                                        this.state.settingFormValues
                                          .small_time_increment
                                      }
                                      name="small_time_increment"
                                      placeholder="Enter Small Time Increment"
                                    />
                                  </div>
                                  <span>&nbsp;seconds</span>
                                </div>
                              </div>
                              <div className="row">
                                <div class="form-group has-success mb-2">
                                  <label class="col-md-4 control-input label">
                                    Large Time Increment
                                    <br />
                                    <span>
                                      (
                                      <img
                                        loading="lazy"
                                        style={{ maxWidth: "20px" }}
                                        src={revindIncrement}
                                      />
                                      &nbsp;
                                      <img
                                        style={{ maxWidth: "20px" }}
                                        src={forwardIncrement}
                                      />
                                      <label className="label">buttons</label>)
                                    </span>
                                    <span></span>
                                  </label>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <div className="col input">
                                    <input
                                      type="text"
                                      class="form-control"
                                      onChange={(e) => this.settingChange(e)}
                                      id="large_time_increment"
                                      autoComplete="off"
                                      min="0"
                                      onKeyPress={(e) => {
                                        if (
                                          !/[0-9+]/.test(e.key) ||
                                          e.target.value.length >= 12
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onPasteCapture={(e) => {
                                        let clipboardData, pastedData;
                                        clipboardData =
                                          e.clipboardData ||
                                          window.clipboardData;
                                        pastedData =
                                          clipboardData.getData("Text");
                                        if (
                                          !/[0-9+]/.test(pastedData) ||
                                          pastedData.length >= 12
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      value={
                                        this.state.settingFormValues
                                          .large_time_increment
                                      }
                                      name="large_time_increment"
                                      placeholder="Enter Large Time Increment"
                                    />
                                  </div>
                                  <span>&nbsp;seconds</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </div>
                      )}
                      {this.state.openPopup && this.addUser()}
                    </div>
                  </>
                )}
              </div>
              <Timeline
                allEvents={this.state.allEvents}
                pauseVideo={(a) => this.pauseVideo(a)}
                playVideo={(a) => this.playTimelineVideo(a)}
                addTimeLine={() => this.addTimeLine()}
                deleteTimeline={(a) => this.deleteTimeline(a)}
                rewindVideo={(a) => this.rewindVideo(a)}
                playEventsVideo={(a, b, c) => this.playEventsVideo(a, b, c)}
                totalDuration={this.state.totalDuration}
                playingCommon={this.state.playingCommon}
                timelineDetails={this.state.timelineDetails}
                varPlayerValues={this.state.varPlayerValues}
                allObjects={this.state.allObjects}
                toHHMMSS={(a) => this.toHHMMSS(a)}
                convertToHHMMssSSS={(a) => this.convertToHHMMssSSS(a)}
                getStyle={(a) => this.getStyle(a)}
                playAllTimelineVideo={(a) => this.playAllTimelineVideo(a)}
                saveTimeLineObject={(a) => this.saveTimeLineObject(a)}
                progress={(e) => this.clickProgressToPlace(e)}
                share={this.props.share}
                forwardVideo={(a) => this.forwardVideo(a)}
                backwardVideo={(a) => this.backwardVideo(a)}
              />
              {this.props.share && (
                <>
                  <div className="float-right me-3 label fw-bold fs-6 position-absolute end-0">
                    {" "}
                    If you would like more information about Piqtime,
                    contact&nbsp;
                    <a
                      className="link-color 
                    text-decoration-underline fs-6"
                    >
                      piqtime@gmail.com
                    </a>
                  </div>
                </>
              )}
            </div>
          </BlockUi>
        </div>
      </>
    );
  }
  forwardVideo = (a) => {
    let timeline = this.state.timelineDetails[a];
    if (IsExists(timeline)) {
      let playId = `${timeline.id}-playVideo`;
      this.setState({ playingIndex: playId, position: a }, () => {
        let playTime = document
          .getElementById(playId)
          .getAttribute("aria-label");
        fromOld = parseFloat(playTime) + parseFloat(10);
        this.seekTo(fromOld);
        this.play();
      });
    }
  };
  backwardVideo = (a) => {
    let timeline = this.state.timelineDetails[a];
    if (IsExists(timeline)) {
      let playId = `${timeline.id}-playVideo`;
      this.setState({ playingIndex: playId, position: a }, () => {
        let playTime = document
          .getElementById(playId)
          .getAttribute("aria-label");
        fromOld = parseFloat(playTime) - parseFloat(10);
        this.seekTo(fromOld);
        this.play();
      });
    }
  };
  handleSeek = (e) => {};

  highLightTags = (id) => {
    let result = false;
    if (this.state.formData && this.state.formData.tag_ids) {
      let tagIds = this.state.formData.tag_ids.split(",");

      if (tagIds.includes(String(id))) {
        result = true;
      }
    }

    return result;
  };
  insertViewedVideo = () => {
    if (
      !this.state.ViewEditEntry &&
      IsExists(this.state.videoObject) &&
      IsExists(this.state.videoObject.ownerId)
    ) {
      if (
        parseInt(AuthService.getUserId()) !==
          parseInt(this.state.videoObject.ownerId) &&
        !!AuthService.getUserId()
      ) {
        let obj = {
          master_id: this.state.videoObject.id,
          userId: parseInt(AuthService.getUserId()),
        };
        VideoService.insertViewedVideo(obj)
          .then((response) => {
            this.setState({ ViewEditEntry: true });
          })
          .catch(this.setState({ ViewEditEntry: true }));
      }
    }
  };
  getnewTime = (e, btn) => {
    if (e.nativeEvent.offsetX <= btn.clientWidth)
      return (
        (e.nativeEvent.offsetX / btn.clientWidth) * this.state.totalDuration
      );
  };
  clickProgressToPlace = (data) => {
    // click anywhere in the timeline place progress bar to that place
    const btn = document.getElementById("progress-div");
    if (btn !== undefined && btn !== null) {
      let currentTime = this.getnewTime(data.event, btn);
      this.setState(
        {
          playingIndex: data.playVideo,
          position: data.index,
        },
        () => {
          this.unBindSelectedEvent();
          this.clickIconChange(data.index);
          if (IsExists(currentTime)) {
            fromOld = currentTime;
            toOld = this.state.totalDuration;
            if (fromOld < 0) {
              fromOld = 0;
            }
            this.seekTo(fromOld);
            // this.play();
          }
        }
      );
    }
  };
  copyText = () => {
    //copy the share link

    let element = document.createElement("textarea");
    element.value = `${
      window.location.origin
    }/VideoTagging/share?userId=public&search=${btoa(
      this.state.videoObject.id
    ).replace(/=/g, "")}`;
    document.body.appendChild(element);
    element.select();
    document.execCommand("copy");
    document.body.removeChild(element);

    var copyText = document.getElementById("copy");
    copyText.innerHTML = "copied";
  };
  setAllData = (obj) => {
    //setting all data in the all tab
    if (Object.keys(obj).length > 0) {
      this.setState(
        {
          lock: obj.lock,
          videoObject: obj.selectedVideo,
          timelineDetails: obj.selectedVideo.timeline,
        },
        () => {
          this.insertViewedVideo();
          this.setSettings();
          this.accessControl();
          this.editData();
        }
      );
    }
  };
  accessControl = () => {
    // access control for all tabs
    let access = this.state.videoObject.access;
    // if (this.state.lock) {
    //   this.setState({
    //     enableTags: false,
    //     enableEvents: false,
    //     enableAccess: false,
    //     enableSettings: false,
    //   })
    // } else {
    if (access && access.length > 0) {
      let accessData = access[0];
      if (
        parseInt(this.state.videoObject.ownerId) !==
        parseInt(AuthService.getUserId())
      ) {
        this.setState({
          enableEvents: accessData.event_access,
          enableTags: accessData.tag_access,
          enableAccess: accessData.access,
          enableSettings: accessData.setting_access,
        });
      }
      let options = access.filter(
        (a) => parseInt(a.userId) === parseInt(AuthService.getUserId())
      );
      if (options && options.length > 0) {
        let extraAccess = options[0];
        this.setState({
          enableEvents: extraAccess.event_access,
          enableTags: extraAccess.tag_access,
          enableAccess: extraAccess.access,
          enableSettings: extraAccess.setting_access,
        });
      }
    } else {
      this.setState({
        enableTags: true,
        enableEvents: true,
        enableAccess: true,
        enableSettings: true,
      });
    }
    // }
  };

  saveTimeLineObject = async (a) => {
    //save tags in the selected timeline

    let btn = document.getElementById(`${a.k.id}-${a.i}-timelineTags`);
    let timeline = this.state.timelineDetails;

    if (ReactDOM.findDOMNode(btn).className === "grid-item grid-inactive") {
      if (!this.props.share) {
        this.updateTimelineId(a);
        return "";
      }
      let array = [];
      let obj = this.state.timelineDetails[a.i];
      if (IsExists(obj)) {
        if (IsExists(obj.subscribedTags)) {
          array.push(obj.subscribedTags);
        }
        array.push(a.k.id);
      }
      obj.subscribedTags = array.join(",");
      timeline[a.i] = obj;
      this.setState({
        timelineDetails: timeline,
      });
    } else {
      if (!this.props.share) {
        return this.removeTimelineId(a);
      }
      let obj = this.state.timelineDetails[a.i];

      if (IsExists(obj)) {
        let array = IsExists(obj.subscribedTags)
          ? obj.subscribedTags.split(",")
          : [];
        let index = array.findIndex((l) => parseInt(l) === parseInt(a.k.id));
        if (index !== -1) {
          array.splice(index, 1);
        }
        obj.subscribedTags = array.join(",");
        timeline[a.i] = obj;

        this.setState({ timelineDetails: timeline });
      }
    }
  };

  removeTimelineId = (obj) => {
    //remove the selected tags in the timeline
    let data = {
      id: obj.time.id,
      subscribedTimeline: obj.k.id,
    };
    const continueToPlay = () => {
      toOld = this.state.totalDuration;
    };
    this.setState({ blocking: true }, () => {
      this.unBindSelectedEvent();
      continueToPlay();
    });
    VideoService.removeTimelineId(data)
      .then(async (response) => {
        if (response.data.status === 1) {
          await this.props.loadBack(this.state.videoObject.id);
          this.setState({ blocking: false });
        }
      })
      .catch(() => console.error("remove timeline error"));
  };
  updateTimelineId = (obj) => {
    //update timeline while click the tags in the particular timeline
    let data = {
      subscribedTimeline: obj.k.id,
      id: obj.time.id,
    };
    const continueToPlay = () => {
      toOld = this.state.totalDuration;
    };
    this.setState({ blocking: true }, () => {
      this.unBindSelectedEvent();
      continueToPlay();
    });
    VideoService.updateTimelineId(data)
      .then(async (response) => {
        if (response.data.status === 1) {
          await this.props.loadBack(this.state.videoObject.id);
          this.setState({ blocking: false });
        }
      })
      .catch(() => console.error("save error "));
  };
  deleteTimeline = (a) => {
    if (!this.props.share) {
      let obj = {
        id: a,
      };
      this.setState({ blocking: true }, () => {
        this.unBindSelectedEvent();
      });
      VideoService.deleteTimeline(obj).then(async (response) => {
        if (response.data.status === 1) {
          await this.props.loadBack(this.state.videoObject.id);
          setTimeout(() => {
            this.highLightTimelineIcons(a);
          }, 500);
        }
      });
    }
  };
  highLightTimelineIcons = (a) => {
    //hightLight timeline Icons based on deleted timeline
    let index = this.state.timelineDetails.findIndex((l) => l.id === a);
    if (index === this.state.timelineDetails.length - 1) {
      this.setState({ position: 0, blocking: false, playing: false }, () => {
        this.showDarkColorAndPlayIcon(this.state.position);
      });
    } else {
      this.setState(
        { position: index + 1, blocking: false, playing: false },
        () => {
          this.showDarkColorAndPlayIcon(this.state.position);
        }
      );
    }
  };

  onPlayerStop() {
    const duration = toOld - fromOld;
    this.timeoutId = setTimeout(() => {
      this.seekTo(fromOld);
      if (this.player) {
        this.player.pause();
      }
    }, duration * 1000);
  }

  playAllVideo = (timelineArray) => {
    ///play All continueous in the timeline
    if (Object.keys(timelineArray).length > 0) {
      let array = [];
      let subscribedTags = IsExists(timelineArray.subscribedTags)
        ? timelineArray.subscribedTags.split(",")
        : [];
      let func = "or";

      let filteredEvents = [];
      if (func === "or") {
        filteredEvents = this.playAllEventsBySort(
          this.player.getCurrentTime()
        ).filter((d) => {
          let status = true;
          let tags = IsExists(d.tag_ids) ? d.tag_ids.split(",") : [];
          subscribedTags.forEach((d) => {
            if (!tags.includes(d)) {
              status = false;
            }
          });
          if (status) {
            return d;
          }
        });
        if (filteredEvents.length > 0) {
          array = filteredEvents;
        }
      }
      if (array && array.length > 0) {
        let sortArray = [];
        if (this.state.time) {
          sortArray = sortByTime(array);
        } else if (this.state.alpha) {
          sortArray = sortByAlpha(array);
        }
        this.setState({ playAll: true, videoAllEvents: sortArray }, () => {
          const videoPlayCheck = () => {
            let data = this.state.videoAllEvents;
            for (var i = increment; i < data.length; ) {
              if (
                this.state.videoAllEvents &&
                this.state.videoAllEvents.length > 0
              ) {
                fromOld = data[i].start_time;
                toOld = data[i].end_time;
                this.setState({ onPlayEnd: data[i].end_time });
                this.seekTo(fromOld);
                this.play();
                break;
              }
              return data.length;
            }
          };
          videoPlayCheck();
        });
        return;
      }
      increment = 0;
      this.setState(
        {
          playAll: false,
        },
        () => {
          this.pause();
        }
      );
    }
  };
  playAllEventsBySort = (currentTime) => {
    //this method is used to play the after progress bar timeline events

    const finalEndTime = this.state.allEvents.reduce((highest, event) => {
      if (parseInt(event.end_time) > parseInt(highest)) {
        return event.end_time;
      } else {
        return highest;
      }
    }, 0);

    if (parseInt(finalEndTime) === parseInt(currentTime)) {
      return [];
    }
    let options = this.state.allEvents.filter((l) => {
      return parseInt(l.start_time) > parseInt(currentTime);
    });

    if (options && options.length > 0) {
      return options;
    }
    return this.state.allEvents;
  };
  playAll = (timeline, index) => {
    //play All events for the timeline

    if (
      timeline !== null &&
      timeline !== undefined &&
      Object.keys(timeline).length > 0
    ) {
      this.setState(
        { timelineArray: timeline, playerVideo: true, position: index },
        () => {
          return this.playAllVideo(this.state.timelineArray);
        }
      );
    }
    return this.playAllVideo(this.state.timelineArray);
  };
  updateSettingDetails = (obj) => {
    //update setting details
    let form = obj;
    form["id"] = this.state.videoObject.id;

    this.setState({ blocking: true }, () => {
      VideoService.updateSettings(form)
        .then(async (response) => {
          if (response.data.status === 1) {
            await this.props.loadBack(this.state.videoObject.id);
            this.setState({ blocking: false });
          }
        })
        .catch();
    });
  };

  eventsOrderChange = (data) => {
    //events order change in events tab
    if (data === "time") {
      this.setState({
        time: true,
        alpha: false,
        allEvents: sortByTime(this.state.allEvents),
        varPlayerValues: sortByTime(this.state.varPlayerValues),
      });
    } else if (data === "alpha") {
      this.setState({
        time: false,
        alpha: true,
        allEvents: sortByAlpha(this.state.allEvents),
        varPlayerValues: sortByAlpha(this.state.varPlayerValues),
      });
    }
  };

  playVideo = () => {
    let seekVideoStart = +fromOld;
    if (seekVideoStart >= 1) {
      this.seekTo(seekVideoStart);
      this.play();
    } else {
      seekVideoStart = seekVideoStart.toFixed(1);
      console.log("seekVideoStart", seekVideoStart);
      this.seekTo(seekVideoStart);
      this.play();
    }
  };

  pauseVideo = async (index) => {
    //pause video to change the play icon to pause icon
    var common = [],
      obj = {};
    common = this.state.playingCommon;
    obj = this.state.playingCommon[index];
    obj["show"] = false;
    common[index] = obj;
    this.pause();
    this.setState(
      {
        playingCommon: common,
      },
      () => {
        this.unBindSelectedEvent();
      }
    );
  };
  clickIconChange = (index) => {
    // change to play icon
    let showing = this.state.playing;
    let common = [];
    if (index !== "" && index != null) {
      this.state.playingCommon.splice(index, 1);
      common = this.state.playingCommon;
      let obj = {};
      obj["playing"] = true;
      obj["show"] = showing;
      if (this.state.playingCommon.length > 0) {
        common.forEach((k, v) => {
          if (v !== index) {
            this.state.playingCommon.splice(v, 1);
          } else {
            if (this.state.playingCommon[index].playing) {
              obj = this.state.playingCommon[index];
              obj["playing"] = true;
            }
          }
        });
      } else {
        obj["playing"] = true;
      }
      common[index] = obj;
      let playingId = this.state.timelineDetails[this.state.position];
      this.setState({
        playingCommon: common,
        playingIndex: `${playingId.id}-playVideo`,
      });
    }
  };
  showDarkColorAndPlayIcon = (index) => {
    // change to pause icon
    var common = [];
    increment = 0;
    if (index !== "" && index != null) {
      this.state.playingCommon.splice(index, 1);
      common = this.state.playingCommon;
      var obj = {};
      obj["playing"] = true;
      obj["show"] = false;
      if (this.state.playingCommon.length > 0) {
        common.forEach((k, v) => {
          if (v !== index) {
            this.state.playingCommon.splice(v, 1);
          } else {
            if (this.state.playingCommon[index].playing) {
              obj = this.state.playingCommon[index];
              obj["playing"] = false;
              obj["show"] = false;
            } else {
              obj = this.state.playingCommon[index];
              obj["playing"] = true;
              obj["show"] = false;
            }
          }
        });
      } else {
        obj["playing"] = true;
        obj["show"] = false;
      }
      common[index] = obj;

      this.setState({
        playingCommon: common,
      });
    }
  };
  progressColorRemove = () => {
    //remove the progress color
    let data = this.state.timelineDetails;
    for (let key of data) {
      const btn = document.getElementById(`${key.id}-playVideo`);
      if (IsExists(btn)) {
        btn.style.width = 0 + "%";
        this.setState({ playingIndex: "" });
      }
    }
  };
  playAllTimelineVideo = (index) => {
    // play All Events for selected timeline
    let data = this.state.timelineDetails[index];
    increment = 0;
    this.setState(
      { position: index, playingIndex: `${data.id}-playVideo` },
      () => {
        if (IsExists(data)) {
          this.playAll(data, index);
          this.unBindSelectedEvent();
        }
      }
    );
  };
  Pla
  playTimelineVideo = (index) => {
    //play icon click to play
    let data = this.state.timelineDetails[index];
    let playId = `${data.id}-playVideo`;
    let playTime = document.getElementById(playId).getAttribute("aria-label");

    if (IsExists(playTime)) {
      if (parseInt(playTime) === parseInt(this.state.totalDuration)) {
        fromOld = 0;
        toOld = this.state.totalDuration;

        this.playVideo();
        return this.setState({
          position: index,
          playingIndex: playId,
          playAll: false,
          onPlayEnd: this.state.totalDuration,
        });
      }
      fromOld = playTime;
      toOld = this.state.totalDuration;
      this.playVideo();

      this.setState(
        {
          position: index,
          playingIndex: playId,
          playAll: false,
          onPlayEnd: this.state.totalDuration,
        },
        () => {
          this.unBindSelectedEvent();
        }
      );
    }
  };
  setLastPlayed = (obj) => {
    // this method is used to get the last player time in the each in the timeline

    if (IsExists(obj.index)) {
      let data = this.state.timelineDetails[obj.index];
      if (IsExists(data) && Object.keys(data).length > 0) {
        let btn = document.getElementById(`${data.id}-playVideo`);
        let duration = document.getElementById(`${obj.index}-time`);
        if (IsExists(btn)) {
          btn.setAttribute("aria-label", obj.vDuration);
        }
        if (IsExists(duration)) {
          duration.innerHTML = `${this.convertToHHMMssSSS(
            obj.vDuration
          )} / ${this.convertToHHMMssSSS(this.state.totalDuration)}`;
        }
      }
    }
  };
  rewindVideo = (index) => {
    //change to play icon;

    let data = this.state.timelineDetails[index];
    if (IsExists(data)) {
      let playId = `${data.id}-playVideo`;
      this.setState(
        {
          playingIndex: playId,
          position: index,
        },
        () => {
          fromOld = 0;
          toOld = this.state.totalDuration;
          this.seekTo(fromOld);
          this.unBindSelectedEvent();
          this.play();
        }
      );
    }
  };
  handleDuration = (totalDuration) => {
    //get total duration of the video
    //alert("handleDuration" + totalDuration)
    let data = totalDuration - 1;
    if (totalDuration !== data) {
      this.setState({
        totalDuration: data,
        onPlayEnd: data,
      });
    }
  };
  removeAll = (formDataValue) => {
    //remove selected Events in the events tab
    selected_array = [];

    if (
      this.state.editForm &&
      this.state.enableEvents &&
      formDataValue &&
      formDataValue.id
    ) {
      let obj = {
        id: formDataValue.id,
      };
      this.setState({ blocking: true }, () => {
        VideoService.deleteEvents(obj)
          .then(async (response) => {
            if (response.data.status === 1) {
              await this.props.loadBack(this.state.videoObject.id);
              this.dataRemove();
              this.objectColorRemove();
              if (IsExists(this.state.nextEventOnEdit)) {
                let nextEventsForEdit = this.state.allEvents.filter(
                  (l) => parseInt(l.id) === parseInt(this.state.nextEventOnEdit)
                );
                selected_array.push(nextEventsForEdit);

                if (nextEventsForEdit.length > 0) {
                  this.setState(
                    {
                      blocking: false,
                      editForm: true,
                      formData: nextEventsForEdit[0],
                    },
                    () => {
                      console.log(nextEventsForEdit[0].id);
                      try {
                        this.eventPlay(nextEventsForEdit[0].id);
                      } catch (exp) {
                        //try other modern gateway
                        this.triggerClickById(nextEventsForEdit[0].id);
                      }
                    }
                  );
                }
              } else {
                this.setState({
                  blocking: false,
                  editForm: false,
                });
              }
            } else {
              this.setState({
                blocking: false,
                editForm: false,
              });
            }
          })
          .catch((expection) => {
            console.error("delete events error", expection);
          });
      });
    }
  };

  triggerClickById = (id) => {
    // Find the <li> element with the specified ID and trigger click event
    const element = document.getElementById(id);
    if (element) {
      if (typeof element.click === "function") {
        element.click();
      } else {
        const event = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        });
        element.dispatchEvent(event);
      }
    }
  };

  sliderSet = (value) => {
    //this method used to adjust the time in position & duration start

    if (this.state.enableEvents) {
      let data = 0;
      if (value == "large_time_forward") {
        data =
          parseInt(this.state.onPlayStart) +
          parseInt(this.state.settingFormValues.large_time_increment);
      } else if (value == "large_time_backward") {
        if (this.state.onPlayStart !== 0 && this.state.onPlayStart !== 1) {
          data =
            parseInt(this.state.onPlayStart) -
            parseInt(this.state.settingFormValues.large_time_increment);
        }
      } else if (value == "small_time_forward") {
        data =
          parseFloat(this.state.onPlayStart) +
          parseFloat(this.state.settingFormValues.small_time_increment);
      } else if (value == "small_time_backward") {
        if (this.state.onPlayStart !== 0 && this.state.onPlayStart !== 1) {
          data =
            parseInt(this.state.onPlayStart) -
            parseInt(this.state.settingFormValues.small_time_increment);
        }
      }

      if (this.state.editForm) {
        let form = this.state.formData;
        form["start_time"] = data.toString();
        form["end_time"] = this.state.onPlayEnd.toString();
        this.setState(
          {
            formData: form,
          },
          () => {
            this.updateEvents();
            fromOld = data;
            toOld = toOld;

            this.seekTo(fromOld);
          }
        );
      }
      this.setState(
        {
          onPlayStart: data,
        },
        () => {}
      );
    }
  };
  sliderSetEnd = (value) => {
    //this method used to adjust the time in position & duration start
    if (this.state.enableEvents) {
      let data = this.state.totalDuration;
      if (value == "large_time_forward") {
        if (this.state.onPlayEnd !== this.state.totalDuration) {
          data =
            parseInt(this.state.onPlayEnd) +
            parseInt(this.state.settingFormValues.large_time_increment);
        }
      } else if (value == "large_time_backward") {
        if (this.state.onPlayEnd !== 0) {
          data =
            parseInt(this.state.onPlayEnd) -
            parseInt(this.state.settingFormValues.large_time_increment);
        }
      } else if (value == "small_time_forward") {
        if (this.state.onPlayEnd != this.state.totalDuration) {
          data =
            parseFloat(this.state.onPlayEnd) +
            parseFloat(this.state.settingFormValues.small_time_increment);
        }
      } else if (value == "small_time_backward") {
        if (this.state.onPlayEnd !== 0) {
          data =
            parseFloat(this.state.onPlayEnd) -
            parseFloat(this.state.settingFormValues.small_time_increment);
        }
      }
      if (this.state.editForm) {
        let form = this.state.formData;
        form["start_time"] = this.state.onPlayStart.toString();
        form["end_time"] = data.toString();
        this.setState(
          {
            formData: form,
          },
          () => {
            this.updateEvents();
            fromOld = this.player.getCurrentTime();
            toOld = data;

            this.seekTo(fromOld);
          }
        );
      }
      this.setState({
        onPlayEnd: data,
      });
    }
  };
  settingChange = (event) => {
    //setings onChange method and store the data without click button
    if (this.state.enableSettings) {
      let form = this.state.settingFormValues;
      if (
        event.target.name === "event_name_prefix" ||
        event.target.name === "timeline_name_prefix"
      ) {
        form[event.target.name] = event.target.value.replace(
          /(\b[a-z](?!\s))/g,
          (m, n) => n.toUpperCase()
        );
      } else {
        form[event.target.name] = event.target.value;
      }
      let onPlayStart = form.event_start_time_offset;
      this.setState(
        {
          settingFormValues: form,
          onPlayStart: onPlayStart,
        },
        () => {
          clearTimeout(this.timer);
          if (event.target.value !== "" && event.target.value !== null) {
            const newTimer = setTimeout(() => {
              this.updateSettingDetails(this.state.settingFormValues);
            }, 1000);
            this.timer = newTimer;
          }
        }
      );
    } else {
      Toast.fire({
        icon: "warning",
        title: "You are not allowed",
      });
    }
  };
  setSettings = () => {
    //set starting data into the settings field
    let form = this.state.settingFormValues;
    let obj = this.state.videoObject;
    form["event_name_prefix"] = obj.event_name_prefix;
    form["event_start_time_offset"] = obj.event_start_time_offset;
    form["event_default_length"] = obj.event_default_length;
    form["event_default_color"] = obj.event_default_color;
    form["timeline_name_prefix"] = obj.timeline_name_prefix;
    form["large_time_increment"] = obj.large_time_increment;
    form["small_time_increment"] = obj.small_time_increment;
    this.setState({
      settingFormValues: form,
    });
  };
  addTimeLine = () => {
    //add timeline method
    if (!this.props.share) {
      let timelineLength = parseInt(this.state.timelineLength) + 1;
      let obj_data = {
        master_id: this.state.videoObject.id,
        timelineName:
          this.state.settingFormValues.timeline_name_prefix +
          " " +
          timelineLength,
        timelineLength: timelineLength,
        description: "sample Description",
      };
      this.setState({ blocking: true, timelineLength: timelineLength }, () => {
        this.getTimeLineDetails(obj_data);
        this.unBindSelectedEvent();
      });
    }
  };
  getCloneDetails = (result) => {
    //get Another clone timeline
    let array = this.state.varPlayerValues;
    let timelineDetails = [];
    array.push(result);
    let obj = {
      master_id: this.state.videoObject.id,
    };
    VideoService.getAllCloneDetails(obj).then(async (response) => {
      let result = response.data.data.data;
      result.forEach((k, v) => {
        timelineDetails.push(k);
      });
      await this.props.loadBack(this.state.videoObject.id);
      this.setState({
        timelineDetails: timelineDetails,
        blocking: false,
      });
    });
  };
  getTimeLineDetails = (data) => {
    //getting timeline details
    VideoService.getCloneTimeDetails(data).then((response) => {
      let result = response.data.data;
      this.getCloneDetails(result);
    });
  };
  removeObject = (v) => {
    //this method used to remove the tags
    if (this.state.enableTags) {
      let deletedObject = this.state.allObjects.splice(v, 1);
      this.setState(
        {
          allObjects: this.state.allObjects,
        },
        () => {
          VideoService.deleteObject(Object.assign({}, deletedObject[0])).then(
            async (response) => {
              if (response.data.status === 1) {
                await this.props.loadBack(this.state.videoObject.id);
              }
            }
          );
        }
      );
    }
  };
  playEventsVideo = async (k, index, id) => {
    //this method is used to play the particular events

    if (IsExists(k)) {
      fromOld = k.start_time;
      toOld = k.end_time;

      this.playVideo();
      this.selectObject(k.id);
      let ids = IsExists(k.tag_ids) ? k.tag_ids.split(",") : [];
      selected_array.push(k);
      this.colorChangeForTags(ids);

      let form = k;

      this.setState(
        {
          playingIndex: id,
          playAll: false,
          formData: form,
          position: index,
          editForm: true,
          onPlayStart: k.start_time,
          onPlayEnd: k.end_time,
          playerVideo: true,
        },
        () => {
          this.colorCheckForEvents(this.state.formData);
        }
      );
    }
  };
  progressChange = () => {
    // video playing red progress bar
    if (IsExists(this.state.playingIndex)) {
      this.setLastPlayed({
        index: this.state.position,
        vDuration: this.player.getCurrentTime(),
      }); // last played insert based on timeline
      this.progressRedBar();
    }
  };
  progressRedBar = () => {
    //this method is used to progress the redprogress bar in the timeline based on calculation
    var totalDuration = this.state.totalDuration;
    let width = parseFloat(this.player.getCurrentTime() * 100) / totalDuration;

    if (width > 0) {
      if (this.state.editForm && this.state.formData?.id) {
        var currentTime = this.player.getCurrentTime(); // Retrieve the current playing time

        var elementWidth = (currentTime * 100) / totalDuration;

        // Apply the element width to the progress bar
        var progressBar = document.getElementById(this.state.playingIndex);
        if (progressBar) {
          progressBar.style.width = elementWidth + "%";
          progressBar.style.backgroundColor = "red";
        }
      } else {
        let progressBar = document.getElementById(this.state.playingIndex);
        if (IsExists(progressBar)) {
          document.getElementById(this.state.playingIndex).style.width =
            width + "%";
          document.getElementById(
            this.state.playingIndex
          ).style.backgroundColor = "red";
        }
      }
    }
  };

  getStyle = (k) => {
    //getting style for every events based on startime and endtime
    if (Object.keys(k).length > 0 && this.state.totalDuration !== 0) {
      var time = this.state.totalDuration;
      let difference = parseFloat(k.end_time) - parseFloat(k.start_time);
      let width = (difference * 100) / time;
      let left = (parseFloat(k.start_time) * 100) / time;

      if (width !== Infinity) {
        return {
          backgroundColor: k.eventColor,
          width: width + "%",
          position: "absolute",
          left: left + "%",
          minWidth: "5px",
        };
      }
    }
  };
  share = () => {
    // this method is used to share the video
    if (this.state.shareValue && this.state.shareValue.length > 0) {
      let object = {
        message: "video share",
        master_id: this.state.videoObject.id,
        user_id: this.state.shareValue[0].id,
        sender_id: parseInt(AuthService.getUserId()),
      };
      VideoService.saveSharedVideo(object).then((response) => {
        if (response.data.status === 1) {
          // Toast.fire({
          //   title: "Shared successFully !!!",
          //   icon: "success"
          // })
        }
      });
    }
  };
  editData = async () => {
    //set all data to events and tag and setting values while click the edit video
    let obj = this.state.videoObject,
      accessArray = [],
      extraArray = [],
      extraUser = [],
      sortArray = [];
    array = [];

    if (obj) {
      if (obj.access && obj.access.length > 0) {
        obj.access.forEach((element) => {
          if (element.name !== null && element.userId !== null) {
            let obj = element;
            extraUser.push(obj);
            extraArray.push(element);
          } else {
            accessArray.push(element);
            this.setState({ usersObject: element });
          }
        });
      }
      if (this.state.time) {
        sortArray = sortByTime(obj.events);
      } else {
        sortArray = sortByAlpha(obj.events);
      }
      let maxValue = 0;
      if (sortArray && sortArray.length > 0) {
        maxValue = sortArray.reduce(
          (max, obj) => (obj.eventLength > max ? obj.eventLength : max),
          sortArray[0].eventLength
        );
      }
      // let highestTimelineLength =
      //   obj.timeline &&
      //   obj.timeline.reduce((max, current) => {
      //     return parseInt(current.timelineLength) > parseInt(max)
      //       ? parseInt(current.timelineLength)
      //       : parseInt(max);
      //   }, 0);
      let highestTimelineLength = obj.timeline?.length || 0;

      await this.setState({
        accessExtraDetails: extraArray,
        accessDetails: accessArray,
        extraUser: extraUser,
        allEvents: sortArray,
        eventLength: maxValue,
        timelineLength: highestTimelineLength,
        extraCommonValue: extraArray,
        timelineDetails: obj.timeline,
        varPlayerValues: sortArray,
        allObjects: sortByAlpha(obj.tags),
      });
    }
  };
  //unused
  onYoutubeInfoChange = (event) => {
    let form = this.state.youtubeInfoObject;
    form[event.target.name] = event.target.value;
    this.setState({
      youtubeInfoObject: form,
    });
  };
  extraHandleChange = (event, index) => {
    // handle for access tab extra added user
    var common = this.state.extraCommonValue;
    var obj = {};
    if (this.state.enableAccess) {
      if (this.state.extraCommonValue[index]) {
        obj = this.state.extraCommonValue[index];
      }
      obj[event.target.name] = event.target.checked ? true : false;
      common[index] = obj;
      this.setState(
        {
          extraCommonValue: common,
        },
        () => {
          let form = {};

          let array = this.state.extraCommonValue[index];
          array["senderId"] = AuthService.getUserId();
          array["master_id"] = this.state.videoObject.id;
          let data_push = [];
          data_push.push(array);

          form["extraAccess"] = data_push;

          return this.saveExtraUsers(form);
        }
      );
    } else {
      Toast.fire({
        title: "Not Allowed",
        icon: "warning",
      });
    }
  };
  saveExtraUsers = (data) => {
    // this method is used to save extra users

    this.setState({ blocking: true, openPopup: false }, () => {
      VideoService.saveExtraAccess(data).then(async (response) => {
        if (response.data.status === 1) {
          await this.props.loadBack(this.state.videoObject.id);
          this.setState({ blocking: false });
        }
      });
    });
  };
  addUser = () => {
    //this method is used to add users in access tab
    return (
      <Modal show={true} size="md" centered>
        <Modal.Header className="border-0">
          <div className="fw-bold fs-5 uppercase border-0"></div>
        </Modal.Header>
        <Form noValidate validated={this.state.validated}>
          <Modal.Body className="">
            <div className="basicInfoWrapper newServiceFormWrapper">
              <label className="col-md-12 control-label">
                Choose User
                <Typeahead
                  className="type-head rounded"
                  type="search"
                  labelKey="name"
                  id="user_search"
                  name="user_search"
                  onChange={(value) => {
                    this.setState({ userSearch: value });
                  }}
                  onInputChange={this.handleRefferFieldChange}
                  options={this.state.userDetails}
                  placeholder="username"
                  selected={this.state.userSearch}
                />
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer className="CNSFooter">
            <button
              onClick={(e) => this.saveUsers(e)}
              className="rounded defaultBtn"
              type="button"
            >
              Allow Access
            </button>
            <button
              className="rounded defaultBtn"
              onClick={() => {
                this.setState({ openPopup: false });
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };
  saveUsers = () => {
    //this method is used to save the users
    if (this.state.userSearch.length > 0) {
      userData.push(this.state.userSearch[0]);
      let form = {},
        usersArray = [];
      if (this.state.userSearch && this.state.userSearch[0]) {
        let data = this.state.extraUser.filter(
          (l) => parseInt(l.userId) === this.state.userSearch[0].id
        );
        if (data && data.length > 0) {
          return this.setState({ openPopup: false });
        }
        this.state.userSearch.forEach((k, v) => {
          form["tag_access"] = false;
          form["event_access"] = false;
          form["access"] = false;
          form["setting_access"] = false;
          form["view"] = false;
          form["senderId"] = parseInt(AuthService.getUserId());
          form["userId"] = k.id;
          form["name"] = k.name;
          form["user_type"] = parseInt(k.user_type);
          form["master_id"] = this.state.videoObject.id;
          usersArray.push(form);
        });
        let finalForm = {};
        finalForm["extraAccess"] = usersArray;
        this.saveExtraUsers(finalForm);
      }
    } else {
      Toast.fire({
        icon: "error",
        title: "Please Choose User!!!",
      });
    }
  };
  handleRefferFieldChange = (input, e) => {
    //on type getting users details in access tab
    if (input.length >= 3) {
      let obj = {
        search: input,
        pageNo: 0,
      };
      VideoService.getAllusersName(obj).then((response) => {
        var result = response.data.data.userList;
        if (result.length > 0) {
          let filterData = result.filter((l) => {
            return parseInt(l.id) !== parseInt(AuthService.getUserId());
          });
          this.setState({
            userDetails: filterData,
          });
        } else {
          this.setState({
            userDetails: [],
          });
        }
      });
    }
  };
  handleRefferFieldChangeForShare = (input, e) => {
    // on type getting data for share tab
    if (input.length >= 3) {
      let obj = {
        search: input,
        pageNo: 0,
      };
      VideoService.getAllusersName(obj).then((response) => {
        var result = response.data.data.userList;
        if (result.length > 0) {
          let filterData = result.filter((l) => {
            return parseInt(l.id) !== parseInt(AuthService.getUserId());
          });
          this.setState({
            shareDetails: filterData,
          });
        } else {
          this.setState({
            shareDetails: [],
          });
        }
      });
    }
  };
  handleChange = (event) => {
    // handle change for access tab default public data in access tab
    if (this.state.enableAccess) {
      let form = this.state.usersObject;
      form[event.target.name] = event.target.checked ? true : false;
      this.setState(
        {
          usersObject: form,
        },
        () => {
          let object = {};
          if (this.state.accessDetails[0]) {
            object = {
              master_id: this.state.videoObject.id,
              view: this.state.usersObject.event_access
                ? this.state.usersObject.event_access
                : true,
              event_access: this.state.usersObject.event_access
                ? this.state.usersObject.event_access
                : false,
              setting_access: this.state.usersObject.setting_access
                ? this.state.usersObject.setting_access
                : false,
              access: this.state.usersObject.access
                ? this.state.usersObject.access
                : false,
              tag_access: this.state.usersObject.tag_access
                ? this.state.usersObject.tag_access
                : false,
              user_id: 0,
              id: this.state.accessDetails[0].id,
            };
          } else {
            object = {
              master_id: this.state.videoObject.id,
              event_access: this.state.usersObject.event_access
                ? this.state.usersObject.event_access
                : false,
              setting_access: this.state.usersObject.setting_access
                ? this.state.usersObject.setting_access
                : false,
              access: this.state.usersObject.access
                ? this.state.usersObject.access
                : false,
              tag_access: this.state.usersObject.tag_access
                ? this.state.usersObject.tag_access
                : false,
              user_id: 0,
              id: 0,
            };
          }
          this.setState({ blocking: true }, () => {
            VideoService.saveAccess(object).then(async (response) => {
              if (response.data.status === 1) {
                let array = [];
                array.push(response.data.data.data);
                await this.props.loadBack(this.state.videoObject.id);
                this.setState({
                  accessDetails: array,
                  blocking: false,
                });
              }
            });
          });
        }
      );
    } else {
      Toast.fire({
        icon: "warning",
        title: "You are not allowed !!!",
      });
    }
  };
  removeUser = (index, userData) => {
    // remove the created users in access tab

    if (this.state.enableAccess) {
      let obj = {
        id: this.state.extraUser[index].id,
      };
      if (IsExists(userData.master_id) && IsExists(userData.userId)) {
        obj["master_id"] = userData.master_id;
        obj["user_id"] = userData.userId;
      }
      if (this.state.extraUser[index]) {
        this.setState({ blocking: true }, () => {
          VideoService.deleteAccessDetails(obj).then((response) => {
            if (response.data.status === 1) {
              this.state.extraCommonValue.splice(index, 1);
              this.state.extraUser.splice(index, 1);
              this.state.accessExtraDetails.splice(index, 1);
              let array = this.state.extraCommonValue;
              this.setState({
                blocking: false,
                extraCommonValue: array,
                extraUser: this.state.extraUser,
                accessExtraDetails: this.state.accessExtraDetails,
              });
            }
          });
        });
      }
    }
  };
  userNavigation = (event) => {
    //user navigation tab
    if (event.target.id === "access") {
      this.setState({
        access: true,
        setting: false,
        videoInfo: false,
        events: false,
        share: false,
        tags: false,
      });
    } else if (event.target.id === "video") {
      this.setState({
        access: false,
        setting: false,
        videoInfo: true,
        events: false,
        share: false,
        tags: false,
      });
    } else if (event.target.id === "events_tab") {
      this.setState({
        access: false,
        setting: false,
        videoInfo: false,
        events: true,
        share: false,
        tags: false,
      });
    } else if (event.target.id === "share") {
      this.setState({
        access: false,
        setting: false,
        videoInfo: false,
        events: false,
        share: true,
        tags: false,
      });
    } else if (event.target.id === "tags_tab") {
      this.setState({
        access: false,
        setting: false,
        videoInfo: false,
        events: false,
        share: false,
        tags: true,
      });
    } else {
      this.setState({
        access: false,
        setting: true,
        videoInfo: false,
        events: false,
        share: false,
        tags: false,
      });
    }
  };
  addButton = (data) => {
    //this method is used to save objects in tags tab
    if (data && data.name) {
      this.setState({ selectedObjectData: data });
    } else {
      this.setState({ selectedObjectData: [] });
    }
    Swal.fire({
      title: "Enter Name or Something",
      input: "text",
      inputValue: data && data.name ? data.name : "",
      inputAttributes: {
        autocapitalize: "off",
        placeholder: "Enter Some Text..........",
        onkeypress: "return /^[a-zA-Z0-9_ ]*$/i.test(event.key)",
      },
      showCancelButton: true,
      confirmButtonText: data && data.name ? "update" : "save",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: (data) => {
        if (!IsExists(data)) {
          Swal.showValidationMessage("Please Enter Text or Something");
          return false;
        } else {
          if (data) {
            this.saveObjects(data);
          }
        }
      },
    });
  };
  saveObjects = (name) => {
    //save objects method called from addButton
    if (this.state.enableTags) {
      let object = {
        name: name,
        master_id: this.state.videoObject.id,
        is_active: true,
        acronym: "MSD",
        videoId: this.state.videoObject.id,
        user_id: 1,
        id: this.state.selectedObjectData
          ? this.state.selectedObjectData.id
          : "",
      };
      if (this.state.selectedObjectData && this.state.selectedObjectData.name) {
        return this.updateObjects(object); ///this is used to update the objects
      }
      this.saveObject(object);
    } else {
      Toast.fire({
        icon: "warning",
        title: "You are not allowed!!!",
      });
    }
  };
  saveObject = (object) => {
    this.setState({ blocking: true }, () => {
      VideoService.saveObjects(object).then((response) => {
        this.setState({ blocking: false });
        if (response.data.status === 1) {
          let object_array = this.state.allObjects;
          object_array.push(response.data.data);
          this.setState({
            allObjects: sortByAlpha(object_array),
          });
        } else if (response.data.status === 0) {
          Toast.fire({
            icon: "error",
            title: `Duplicate Name ${response.data.data}`,
          });
        }
      });
    });
  };
  updateObjects = (object) => {
    ///this method is used to update the objects
    this.setState({ blocking: true }, () => {
      VideoService.updateObjects(object).then(async (response) => {
        this.setState({ blocking: false });
        if (response.data.status === 1) {
          await this.props.loadBack(this.state.videoObject.id);
        } else if (response.data.status === 0) {
          Toast.fire({
            icon: "error",
            title: `Duplicate Name ${response.data.data}`,
          });
        }
      });
    });
  };
  selectEventsRemove = () => {
    //empty the BG color for selected events
    if (IsExists(this.state.allEvents)) {
      for (let k of this.state.allEvents) {
        const btn = document.getElementById(k.id);
        if (IsExists(btn)) {
          btn.style.backgroundColor = "white";
          btn.classList.remove("activeBlueObject");
        }
      }
    }
  };

  selectObject = (value) => {
    // BG color change selected events
    if (IsExists(value)) {
      const elements = document.querySelectorAll('[value="' + value + '"]');

      if (elements.length > 0) {
        const btn = elements[0];

        const clonebtn = btn;
        let nextElementId = null;
        if (
          IsExists(clonebtn.parentNode) &&
          IsExists(clonebtn.parentNode.nextElementSibling) &&
          IsExists(clonebtn.parentNode.nextElementSibling.id)
        ) {
          let elemIds = clonebtn.parentNode.nextElementSibling.id;
          elemIds = elemIds.split("_");
          nextElementId = elemIds[1];
        }
        this.setState({
          editForm: true,
          nextEventOnEdit: nextElementId,
        });
      }
    }
  };

  handleEvents = (e) => {
    // onChange handle events in events tab
    let targetElem = e.target;
    let form = {},
      start_time = 0,
      end_time = 0;
    selected_array = [];
    let obj = this.state.videoObject;
    this.selectObject(e.target.value);
    this.placeHeaderEvents(e.target.value);

    if (obj && obj.events) {
      obj.events.map((k) => {
        if (parseInt(k.id) === parseInt(e.target.value)) {
          form["description"] = k.description === null ? "" : k.description;
          form["name"] = k.name;
          form["eventColor"] = k.eventColor;
          form["id"] = k.id;
          form["start_time"] = k.start_time;
          form["end_time"] = k.end_time;
          form["tag_ids"] = k.tag_ids;
          form["events"] = e.target.value;

          this.colorCheckForEvents(form);

          start_time = k.start_time;
          end_time = k.end_time;
          this.objectColorRemove();
          selected_array.push(k);

          let splittedTagIds =
            k.tag_ids != "" && k.tag_ids !== null && k.tag_ids.split(",");
          if (splittedTagIds && splittedTagIds.length > 0) {
            this.colorChangeForTags(splittedTagIds);
          }
        }
      });
    }
    form["events"] = e.target.value;
    this.setState({
      formData: form,
      onPlayStart: start_time,
      onPlayEnd: end_time,
    });
  };

  eventPlay = (eventId) => {
    // onChange handle events in events tab
    let form = {},
      start_time = 0,
      end_time = 0;
    selected_array = [];
    let obj = this.state.videoObject;
    this.selectObject(eventId);
    this.placeHeaderEvents(eventId);

    if (obj && obj.events) {
      obj.events.map((k) => {
        if (parseInt(k.id) === parseInt(eventId)) {
          form["description"] = k.description === null ? "" : k.description;
          form["name"] = k.name;
          form["eventColor"] = k.eventColor;
          form["id"] = k.id;
          form["start_time"] = k.start_time;
          form["end_time"] = k.end_time;
          form["tag_ids"] = k.tag_ids;
          form["events"] = eventId;

          this.colorCheckForEvents(form);

          start_time = k.start_time;
          end_time = k.end_time;
          this.objectColorRemove();
          selected_array.push(k);

          let splittedTagIds =
            k.tag_ids != "" && k.tag_ids !== null && k.tag_ids.split(",");
          if (splittedTagIds && splittedTagIds.length > 0) {
            this.colorChangeForTags(splittedTagIds);
          }
        }
      });
    }
    form["events"] = eventId;
    this.setState({
      formData: form,
      onPlayStart: start_time,
      onPlayEnd: end_time,
    });
  };

  colorCheckForEvents = (object) => {
    let form = this.state.colorForm;

    if (
      this.state.settingFormValues.event_default_color.trim() ===
      object.eventColor.trim()
    ) {
      form["use"] = false;
      form["inherit"] = true;
    } else {
      form["use"] = true;
      form["inherit"] = false;
    }
    this.setState({
      colorForm: form,
      formData: object,
    });
  };
  placeHeaderEvents = async (id) => {
    //if we click the event in event list place the progress bar to that Event in selected timeline
    let index = this.state.playingCommon.findIndex((l) => {
      return IsExists(l) && l.playing == true;
    });
    if (index !== -1) {
      let events = this.state.allEvents.filter((e) => {
        return e.id === id;
      });

      if (events && events.length > 0) {
        let playId = this.state.timelineDetails[index];
        this.setState(
          { playingIndex: `${playId.id}-playVideo`, position: index },
          () => {
            fromOld = events[0].start_time;
            toOld = events[0].end_time;
            this.seekTo(fromOld);
            this.play();
            this.placeRedBarToSelectedEvents();
          }
        );
      }
    }
  };
  placeRedBarToSelectedEvents = () => {
    let width = (parseInt(fromOld) * 100) / this.state.totalDuration;
    let btn = document.getElementById(this.state.playingIndex);

    if (IsExists(btn)) {
      document.getElementById(this.state.playingIndex).style.width =
        width + "%";
      document.getElementById(this.state.playingIndex).style.backgroundColor =
        "red";
    }
  };
  colorChangeForTags = (ids) => {
    //click to change the color for tags
    let obj = this.state.videoObject;

    if (obj) {
      let result = obj.tags.filter((el) => {
        return ids.find((element) => {
          return parseInt(element) === parseInt(el.id);
        });
      });
      if (result && result.length > 0) {
        result.filter((k) => {
          const btn = document.getElementById(k.name);
          if (btn !== undefined && btn !== null) {
            ReactDOM.findDOMNode(btn).className = "grid-item grid-active";
          }
        });
      }
    }
  };
  handleForm = (event) => {
    //handle form for events tab name and description field
    let form = this.state.formData;
    form[event.target.name] = event.target.value;
    this.setState(
      {
        formData: form,
      },
      () => {
        if (this.state.editForm) {
          clearTimeout(this.timer);
          if (event.target.value !== "" && event.target.value !== null) {
            const newTimer = setTimeout(() => {
              this.updateEvents();
            }, 1000);
            this.timer = newTimer;
          }
        }
      }
    );
  };
  updateEvents = (obj) => {
    //update the events
    if (this.state.enableEvents && this.state.editForm) {
      let tagsArray = [];
      selected_array.forEach((k, v) => {
        if (k.tag_ids) {
          tagsArray.push(k.tag_ids);
        }
      });
      let form = this.state.formData;
      form["obj_ids"] = tagsArray.join(",");
      form["start_time"] = this.state.onPlayStart;
      form["end_time"] = this.state.onPlayEnd;
      form["eventColor"] = form.eventColor;

      VideoService.updateEvents(form).then(async (response) => {
        if (response.data.status === 1) {
          this.setState({ formData: response.data.data }, async () => {
            await this.props.loadBack(this.state.videoObject.id);
          });
        }
      });
    }
  };

  updateTagsInEventMenu = (obj) => {
    //update the events
    if (this.state.enableTags && this.state.editForm) {
      let tagsArray = [];
      selected_array.forEach((k, v) => {
        if (k.tag_ids) {
          tagsArray.push(k.tag_ids);
        }
      });
      let form = this.state.formData;
      form["obj_ids"] = tagsArray.join(",");
      form["start_time"] = this.state.onPlayStart;
      form["end_time"] = this.state.onPlayEnd;
      form["eventColor"] = form.eventColor;

      VideoService.updateEvents(form).then(async (response) => {
        if (response.data.status === 1) {
          this.setState({ formData: response.data.data }, async () => {
            await this.props.loadBack(this.state.videoObject.id);
          });
        }
      });
    }
  };
  objectColorRemove = () => {
    //click to remove the highlight color in tags
    let obj = this.state.videoObject;
    if (obj) {
      for (let key of obj.tags) {
        let btn = document.getElementById(key.name);
        if (btn !== undefined && btn !== null) {
          ReactDOM.findDOMNode(btn).className = "grid-item grid-inactive";
        }
      }
    }
  };
  tagsChange = (element, event) => {
    // this method is used to click to change the select and deselect
    const btn = document.getElementById(element.name);
    if (this.state.enableTags) {
      if (ReactDOM.findDOMNode(btn).className === "grid-item grid-inactive") {
        if (this.state.editForm) {
          let form = {
            name: this.state.formData.name,
            description: this.state.formData.description
              ? this.state.formData.description
              : "",
            selected_objectId: element.id,
            id: this.state.currentId,
            tag_ids: element.id,
          };
          selected_array.push(form);
        }
      } else {
        if (this.state.editForm) {
          let index = selected_array.findIndex((l) => {
            return parseInt(l.selected_objectId) === parseInt(element.id);
          });
          if (index !== -1) {
            selected_array.splice(index, 1);
          }
          let ids = [],
            obj_data = {},
            finalArray = [];
          selected_array.map((key, value) => {
            ids = [];
            if (IsExists(key.tag_ids)) {
              let tagIds = key.tag_ids.toString().split(",");

              if (tagIds && tagIds.length > 0) {
                let filteredId = tagIds.filter((l) => {
                  return parseInt(l) !== parseInt(element.id);
                });
                ids.push(filteredId);
              }
            }
            obj_data = key;
            obj_data["tag_ids"] = ids.join(",");
            finalArray.push(obj_data);
            obj_data = {};
          });
          selected_array = finalArray;
        }
      }
      this.updateTagsInEventMenu();
    }
  };
  saveEvents = async () => {
    //this method is used to save the events

    if (this.state.enableEvents && !this.props.share) {
      let eLength = this.state.eventLength + 1;
      let difference =
        this.state.settingFormValues.event_default_length -
        this.state.settingFormValues.event_start_time_offset;
      let obj = {
        // eventColor: this.state.colorForm.inherit
        //   ? this.state.settingFormValues.event_default_color
        //   : this.state.formData.eventColor,
        eventColor: this.state.settingFormValues.event_default_color,
        start_time:
          this.player.getCurrentTime() -
          this.state.settingFormValues.event_start_time_offset,
        end_time: this.player.getCurrentTime() + difference,
        name: this.state.settingFormValues.event_name_prefix + " " + eLength,
        description: this.state.formData.description,
        timeline_id: this.state.timeline_id,
        master_id: this.state.videoObject.id,
        prefix: this.state.settingFormValues.event_name_prefix,
        eventLength: eLength,
        obj_ids: "",
      };

      if (obj.end_time > this.state.totalDuration) {
        obj.end_time =
          this.player.getCurrentTime() +
          Math.abs(this.player.getCurrentTime() - this.state.totalDuration);
      }
      if (
        this.player.getCurrentTime() == "0" ||
        this.player.getCurrentTime == 0
      ) {
        obj.start_time = 0;
      }
      this.saveEventDetails(obj);
    }
  };
  saveEventDetails = (obj) => {
    //save events method
    this.setState({ blocking: true }, () => {
      selected_array = [];
      VideoService.saveEvents(obj)
        .then((response) => {
          if (response.data.data) {
            let result = response.data.data;
            let allData = this.state.allEvents;
            allData.push(result);
            let sortedArray = sortByTime(allData);
            this.unBindSelectedEvent();
            fromOld = result.start_time;
            toOld = result.end_time;
            this.seekTo(fromOld);
            this.setState(
              {
                allEvents: sortedArray,
                varPlayerValues: sortedArray,
                eventLength: obj.eventLength,
                formData: result,
                blocking: false,
                onPlayStart: result.start_time,
                onPlayEnd: result.end_time,
              },
              () => {
                this.props.loadBack(this.state.videoObject.id);
                this.selectObject(result.id);
                this.objectColorRemove();
              }
            );
          }
        })
        .catch((expection) => console.error("create Events error"));
    });
  };
  dataRemove = () => {
    //rmeove the filled data in name and description field
    let form = this.state.formData;
    form["name"] = "";
    form["description"] = "";
    form["events"] = "";
    this.setState({
      formData: form,
      blocking: false,
    });
  };
  onPlayerReady = (event) => {
    //this method is used to check player onReady or not
    selected_array = [];
    fromOld = 0;
    toOld = this.player.getDuration() - 1;

    this.setState({
      onPlayStart: 0,
      onPlayEnd: this.state.totalDuration,
      blocking: false,
    });
  };
  checkVaildEventOnProgress = (watchEventListener) => {
    let acceptEventOnProgress = ["onError", "onPause", "onSeek", "onBuffer"];
    if (acceptEventOnProgress.includes(watchEventListener)) {
      return false;
    } else if (watchEventListener === "onPlay") {
      return true;
    } else {
      return false;
    }
  };
  progress = (state) => {
    //this method is used to check player time cross the endtime or not
    this.progressChange();
    let currentTime = state.playedSeconds;

    fromOld = +fromOld;
    toOld = +toOld;

    if (this.state.totalDuration >= 30) {
      if (parseInt(currentTime) === parseInt(toOld)) {
        if (
          this.state.playing === true &&
          this.checkVaildEventOnProgress(watchEventListener)
        ) {
          if (this.state.playAll) {
            return this.playAll();
          }
          this.pause();
          this.seekTo(toOld);
          toOld = this.state.totalDuration;
          this.showPlayIcon();
        }
      }
    } else {
      //shorts
      if (parseFloat(currentTime) >= parseFloat(toOld)) {
        if (
          this.state.playing === true &&
          this.checkVaildEventOnProgress(watchEventListener)
        ) {
          if (this.state.playAll) {
            return this.playAll();
          }
          this.pause();
          this.seekTo(toOld);
          toOld = this.state.totalDuration;
          this.showPlayIcon();
        }
      }
    }
  };
  showPlayIcon = () => {
    //show play icon after video pause
    this.showDarkColorAndPlayIcon(this.state.position);
  };
  play = () => {
    //play the video method
    this.setState({
      playing: true,
    });
  };
  pause = () => {
    //pause the video method
    this.showPlayIcon(this.state.position);
    this.setState({
      playing: false,
    });
  };
  seekTo = (start) => {
    //seekTo method
    this.player.seekTo(start);
  };
  toHHMMSS = (val) => {
    //change the time format
    var sec_num = parseInt(val, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    if (hours == "00") {
      var time = minutes + ":" + seconds;
    } else {
      var time = hours + ":" + minutes + ":" + seconds;
    }
    return time;
  };

  convertToHHMMssSSS = (val) => {
    // Convert to HH:MM:SS:SSS

    var sec_num = parseInt(val, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num % 3600) / 60);
    var seconds = sec_num % 60;
    var milliseconds = Math.floor((val - sec_num) * 1000);

    milliseconds = milliseconds.toString().padStart(3, "0").substring(0, 2);
    hours = hours.toString().padStart(2, "0");
    minutes = minutes.toString().padStart(2, "0");
    seconds = seconds.toString().padStart(2, "0");

    if (hours == "00") {
      var time = minutes + ":" + seconds + ":" + milliseconds;
    } else {
      var time = hours + ":" + minutes + ":" + seconds + ":" + milliseconds;
    }

    return time;
  };
}

export default memo(NewVideo);


import * as APPCONFIG from "../config/app-config.js";
import auth from "./AuthAPI.js";
import Http from "./Http.js";

class ManageUserService {
  getUserList(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/getAllUsers`,
      data: JSON.stringify(form),
    });
  }

  saveUser(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/saveuser`,
      data: JSON.stringify(form),
    });
  }

  editUser(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/updateUser`,
      data: JSON.stringify(form),
    });
  }

  deleteUser(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/videotagging/deleteUser`,
      data: JSON.stringify(form),
    });
  }

  mobEmailValidation(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/userMobMailValidation`,
      data: JSON.stringify(form),
    });
  }

  filterUser(form) {
    //by city or branch
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/filterUserByCityOrBranch`,
      data: JSON.stringify(form),
    });
  }
  saveLeadPartner(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/leadpartner/saveLeadPartner`,
      data: JSON.stringify(form),
    });
  }

  editLeadPartner(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/leadpartner/updateLeadPartner`,
      data: JSON.stringify(form),
    });
  }

  getLeadPartnerList(form) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/leadpartner/getallLeadPartner`,
      data: JSON.stringify(form),
    });
  }

}

export default new ManageUserService();


class UserMappingService {

  // updateAllVideoDetails(form) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/videotagging/updatevideomaster`,
  //     data: JSON.stringify(form),
  //   });
  // }


  // saveExtraAccess(form) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/videotagging/saveAccess`,
  //     data: JSON.stringify(form),
  //   });
  // }

}

export default new UserMappingService();
